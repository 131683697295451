<template>
  <div>
    <el-form v-loading="loading" ref="form" :model="form" label-width="220px">
      <el-form-item label="订单关系绑定规则">
        <div style="line-height: 2rem">
          分享链接、海报新用户首次访问活动链接
          <div class="y-desc" style="line-height: 1rem">新用户首次打开链接无邀请人，无关联商家时该用户最终无邀请人，其拉新邀请关系链的所有用户均无关联商家</div>
        </div>
        <div style="line-height: 2rem;margin-top: .5rem">
          已访问过活动链接的客户访问其它商家、员工、客户的链接不变动关联、邀请关系
        </div>
        <div style="line-height: 2rem;margin-top: .5rem">
          商家关联客户规则
          <div class="y-desc" style="line-height: 1rem">员工邀请新用户首次打开活动链接</div>
          <div class="y-desc" style="line-height: 1rem">商家管理员邀请新用户首次打开活动链接</div>
          <div class="y-desc" style="line-height: 1rem">已关联商家的客户邀请新用户首次打开活动链接</div>
          <div class="y-desc" style="line-height: 1rem">商家管理员、员工、活动管理员不与其他商户、员工、客户绑定关联关系</div>
        </div>
        <div style="line-height: 2rem;margin-top: .5rem">
          活动管理员推广订单不参与奖励
        </div>
      </el-form-item>
      <el-form-item label="向商家管理员发放的奖励">
        <el-form-item label="直推订单奖励">
          <el-input-number size="small" :min="0" v-model="form.m_manager_invite_award_fee"></el-input-number>
          元
        </el-form-item>
        <el-form-item label="员工直推订单奖励">
          <el-input-number size="small" :min="0" v-model="form.m_manager_clerk_invite_award_fee"></el-input-number>
          元
        </el-form-item>
        <el-form-item label="关联客户直推奖励">
          <el-input-number size="small" :min="0" v-model="form.m_manager_client_invite_award_fee"></el-input-number>
          元
        </el-form-item>
        <el-form-item label="员工自购订单">
          <el-input-number size="small" :min="0" v-model="form.m_manager_clerk_buy_award_fee"></el-input-number>
          元
        </el-form-item>
      </el-form-item>
      <el-divider></el-divider>

      <el-form-item label="向员工发放的奖励">
        <el-form-item label="直推订单奖励">
          <el-input-number size="small" :min="0" v-model="form.m_clerk_invite_award_fee"></el-input-number>
          元
        </el-form-item>
      </el-form-item>
      <el-divider></el-divider>

      <el-form-item label="向客户发放的奖励">
        <el-form-item label="直推订单且自身有关联商家奖励">
          <el-input-number size="small" :min="0" v-model="form.client_with_m_invite_award_fee"></el-input-number>
          元
        </el-form-item>
        <el-form-item label="直推订单且自身无关联商家奖励">
          <el-input-number size="small" :min="0" v-model="form.client_without_m_invite_award_fee"></el-input-number>
          元
        </el-form-item>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "default_invite",
  data() {
    return {
      form: {
        m_manager_invite_award_fee: 0,
        m_manager_clerk_invite_award_fee: 0,
        m_manager_client_invite_award_fee: 0,
        m_manager_clerk_buy_award_fee: 0,
        m_clerk_invite_award_fee: 0,
        client_with_m_invite_award_fee: 0,
        client_without_m_invite_award_fee: 0,
      },
      edit_id: 0,
      loading: false,
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.edit_id = parseInt(this.$route.query.id);
      this.load()
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.$api.plugin.classic.extendFetch({active_id:this.edit_id}).then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
    onSubmit() {
      this.$api.plugin.classic.extendUpdate(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>