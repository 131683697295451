<template>
  <YTabs active-line>
    <YTabPane card name="app" label="微信公众号">
      <el-form>
        <el-form-item label="链接:">
          <el-button @click="$message('复制成功')" v-clipboard:copy="official.link" type="text">{{official.link}}</el-button>
        </el-form-item>
        <el-form-item label="二维码:">
          <a :href="official.code|tomedia" target="_blank"><el-image :src="official.code | tomedia" style="height: 8rem;width: 8rem"></el-image></a>
        </el-form-item>
      </el-form>
    </YTabPane>
    <YTabPane card name="wxapp" label="微信小程序">
      <el-form>
        <el-form-item label="链接:">
          <el-button @click="$message('复制成功')" v-clipboard:copy="wxapp.link" type="text">{{wxapp.link}}</el-button>
        </el-form-item>
        <el-form-item label="二维码:">
          <a :href="wxapp.code | tomedia" target="_blank"><el-image :src="wxapp.code | tomedia" style="height: 8rem;width: 8rem"></el-image></a>
        </el-form-item>
      </el-form>
    </YTabPane>
    <YTabPane card name="ever" label="物料二维码">
      <safe_qrcode :active_id="parseInt($route.query.id)"></safe_qrcode>
    </YTabPane>
    <YTabPane card name="large" label="1280px二维码">
      <Large_qrcode :active_id="parseInt($route.query.id)"></Large_qrcode>
    </YTabPane>
  </YTabs>
<!--  <el-tabs type="border-card">-->
<!--    <el-tab-pane label="微信公众号">-->
<!--      <el-form>-->
<!--        <el-form-item label="链接:">-->
<!--          <el-button @click="$message('复制成功')" v-clipboard:copy="official.link" type="text">{{official.link}}</el-button>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="二维码:">-->
<!--          <a :href="official.code|tomedia" target="_blank"><el-image :src="official.code | tomedia" style="height: 8rem;width: 8rem"></el-image></a>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-tab-pane>-->
<!--    <el-tab-pane label="微信小程序">-->
<!--      <el-form>-->
<!--        <el-form-item label="链接:">-->
<!--          <el-button @click="$message('复制成功')" v-clipboard:copy="wxapp.link" type="text">{{wxapp.link}}</el-button>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="二维码:">-->
<!--          <a :href="wxapp.code | tomedia" target="_blank"><el-image :src="wxapp.code | tomedia" style="height: 8rem;width: 8rem"></el-image></a>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-tab-pane>-->
<!--    <el-tab-pane label="物料二维码">-->
<!--      <safe_qrcode :active_id="parseInt($route.query.id)"></safe_qrcode>-->
<!--    </el-tab-pane>-->
<!--  </el-tabs>-->

</template>

<script>
import safe_qrcode from "./edit_entry/safe_qrcode";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";
import Large_qrcode from "./edit_entry/large_qrcode";
export default {
  name: "edit_entry",
  components:{
    Large_qrcode,
    YTabPane,
    YTabs,
    safe_qrcode
  },
  data(){
    return{
      official:{},
      wxapp:{},
      wx_h5:{
        link:"http://www.baidu.com",
        code:"https://qr.api.cli.im/newqr/create?data=%25E5%258D%25A0%25E4%25BD%258D&level=H&transparent=false&bgcolor=%23FFFFFF&forecolor=%23000000&blockpixel=12&marginblock=1&logourl=&logoshape=no&size=260&kid=cliim&key=262b95eb8ffd6f5fdbf14af44a3c46c6",
      },
      wx_app:{
        link:"/pages/index",
        code:"https://qr.api.cli.im/newqr/create?data=%25E5%258D%25A0%25E4%25BD%258D&level=H&transparent=false&bgcolor=%23FFFFFF&forecolor=%23000000&blockpixel=12&marginblock=1&logourl=&logoshape=no&size=260&kid=cliim&key=262b95eb8ffd6f5fdbf14af44a3c46c6",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$api.active.entry({
        active_id:parseInt(this.$route.query.id)
      }).then(res=>{
        this.official = res.official;
        this.wxapp = res.wxapp;
      })
    }
  }
}
</script>

<style scoped>

</style>