<template>
  <div>
    <div class="flex-def flex-zBetween" style="margin-bottom: 1rem">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="所属商户">
          <y_choose_merchant v-model="searchForm.merchant_id"></y_choose_merchant>
        </el-form-item>
        <el-form-item label="核销员">
          <el-input v-model="searchForm.clerk_nickname" size="medium" placeholder="输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button @click="load" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
            <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <div>
        <el-button @click="exp" type="primary" size="medium">导出</el-button>
      </div>

    </div>
    <el-table :data="list" border :row-class-name="tableRowClassName" style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column prop="coupon_title" label="卡券名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="coupon_fee" label="面值" show-overflow-tooltip></el-table-column>
      <el-table-column prop="merchant_title" label="所属商家" show-overflow-tooltip></el-table-column>
      <el-table-column label="用户信息" width="200" show-overflow-tooltip>
        <template #default="s">
          <div class="flex-def flex-cCenter">
            <el-avatar shape="square" size="small" :src="s.row.member_avatar | tomedia">
              <img :src="s.row.member_avatar | tomedia">
            </el-avatar>
            <span style="margin-left: .5rem">{{s.row.member_nickname}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="car_num" label="车牌" show-overflow-tooltip></el-table-column>
      <el-table-column label="核销员信息" width="200" show-overflow-tooltip>
        <template #default="s">
          <div class="flex-def flex-cCenter">
            <el-avatar shape="square" size="small" v-if="s.row.cashier_avatar" :src="s.row.cashier_avatar | tomedia">
              <img :src="s.row.cashier_avatar | tomedia">
            </el-avatar>
            <span style="margin-left: .5rem">{{s.row.cashier_nickname}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="核销时间" width="180" show-overflow-tooltip>
        <template #default="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
import y_choose_merchant from "@/components/y_choose_merchant"
export default {
  name: "coupon_cashier",
  components:{
    y_choose_merchant,
  },
  data(){
    return{
      page:1,
      size:15,
      total:0,
      list:[],
      searchForm:{
        clerk_nickname:"",
        merchant_id:0,
      },
    }
  },
  mounted() {
    this.load()
  },
  methods:{
    tableRowClassName({rowIndex}) {
      if (rowIndex%2){
        return "warning-row"
      }else {
        return 'success-row';
      }
    },
    exp(){
      this.$api.active.recordCouponCashierExport({
        active_id:parseInt(this.$route.query.id),
        clerk_nickname : this.searchForm.clerk_nickname,
        merchant_id:this.searchForm.merchant_id,
      }).then(res=>{
        location.href = res;
      })
    },
    resetSearch(){
      this.searchForm = {
        clerk_nickname:"",
        merchant_id:0,
      }
      this.load();
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$api.active.recordCouponCashier({
        active_id:parseInt(this.$route.query.id),
        clerk_nickname : this.searchForm.clerk_nickname,
        merchant_id:this.searchForm.merchant_id,
        page : this.page
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  }
}
</script>

<style scoped>

</style>