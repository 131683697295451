<template>
  <div>
    <el-form v-loading="loading" ref="form" :model="form" label-width="120px" style="width: 80rem">
<!--      <el-form-item label="红包限额">-->
<!--        <el-input-number :disabled="$store.getters['user/is_agent']" v-model="form.award_limit"></el-input-number>-->
<!--        元-->
<!--        <div style="line-height: 1rem">-->
<!--          <div class="y-desc">仅管理员可设置</div>-->
<!--          <div class="y-desc">分享红包已发放金额＋转盘红包已发放金额 大于等于 该值时活动不再发放 分享红包、转盘红包</div>-->
<!--        </div>-->
<!--      </el-form-item>-->
      <el-form-item label="强制获取手机号">
        <el-radio v-model="form.must_phone" :label="2">开启</el-radio>
        <el-radio v-model="form.must_phone" :label="1">关闭</el-radio>
        <div class="y-desc" style="margin-top: -1rem">仅小程序端有效</div>
      </el-form-item>
      <el-form-item label="延时授权">
        <el-switch v-model="form.wxapp_auto_sub"></el-switch>
        <div class="y-desc" style="margin-top: -1rem">仅小程序端有效 进入活动延时拉取授权订阅消息</div>
      </el-form-item>
      <el-form-item label="强制关注公众号">
        <el-radio v-model="form.must_follow" :label="2">开启</el-radio>
        <el-radio v-model="form.must_follow" :label="1">关闭</el-radio>
      </el-form-item>
      <el-form-item v-if="form.must_follow === 2" label="公众号二维码">
        <yUploadImg v-model="form.wx_account_qrcode"></yUploadImg>
        <div class="y-desc" style="line-height: 1rem">上传二维码时使用上传的二维码图片</div>
        <div class="y-desc" style="line-height: 1rem">未上传二维码时使用带参二维码，需完成公众号通信配置</div>
      </el-form-item>
<!--      <el-form-item label="购买区域限制">-->
<!--        <el-radio v-model="form.area_limit" :label="2">开启</el-radio>-->
<!--        <el-radio v-model="form.area_limit" :label="1">关闭</el-radio>-->
<!--      </el-form-item>-->
<!--      <el-form-item v-if="form.area_limit === 2" label="限制区域方式">-->
<!--        <el-radio v-model="form.area_limit_func" :label="1">ip地址</el-radio>-->
<!--        <el-radio v-model="form.area_limit_func" :label="2">经纬度</el-radio>-->
<!--      </el-form-item>-->
<!--      <el-form-item v-if="form.area_limit === 2" label="区域范围">-->
<!--&lt;!&ndash;        <el-input v-model="form.area_limit_cities"></el-input>&ndash;&gt;-->
<!--        <el-row :gutter="15">-->
<!--          <el-col :span="4">-->
<!--            <el-input v-model="form.area_limit_radius">-->
<!--              <template #prepend>半径</template>-->
<!--              <template #append>公里</template>-->
<!--            </el-input>-->
<!--          </el-col>-->
<!--          <el-col :span="4">-->
<!--            <el-input v-model="form.area_limit_lat">-->
<!--              <template #prepend>lat</template>-->
<!--            </el-input>-->
<!--          </el-col>-->
<!--          <el-col :span="6">-->
<!--            <el-input v-model="form.area_limit_long">-->
<!--              <template #prepend>long</template>-->
<!--              <template #append>-->
<!--                <div class="y-pointer" @click="area_limit_box=true">选择中心点坐标</div>-->
<!--              </template>-->
<!--            </el-input>-->
<!--          </el-col>-->
<!--          <el-col :span="14">-->
<!--            <el-input style="margin-left: .5rem;margin-top: .5rem" v-model="form.area_limit_url">-->
<!--              <template #append>区域外跳转链接</template>-->
<!--            </el-input>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </el-form-item>-->
      <el-form-item label="依赖分享">
        <el-radio v-model="form.must_from_share" :label="2">开启</el-radio>
        <el-radio v-model="form.must_from_share" :label="1">关闭</el-radio>
        <div class="y-desc" style="margin-top: -1rem">开启后除员工外，其他人必须通过他人分享打开</div>
      </el-form-item>
<!--      <el-form-item label="员工服务奖励">-->
<!--        <el-input v-model="form.service_clerk_award" style="width: 10rem">-->
<!--          <template #append>元</template>-->
<!--        </el-input>-->
<!--        <div class="y-desc" style="line-height: 1rem">仅依赖分享开启后有效</div>-->
<!--        <div class="y-desc" style="line-height: 1rem">为0时不发放</div>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="溯源追踪">-->
<!--        <el-radio v-model="form.tag_first_clerk" :label="2">开启</el-radio>-->
<!--        <el-radio v-model="form.tag_first_clerk" :label="1">关闭</el-radio>-->
<!--        <div class="y-desc" style="margin-top: -1rem">仅开启依赖分享后有效,开启后仅可通过员工分享打开</div>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="员工购买">-->
<!--        <el-radio v-model="form.clerk_buy_award" :label="2">开启</el-radio>-->
<!--        <el-radio v-model="form.clerk_buy_award" :label="1">关闭</el-radio>-->
<!--        <div class="y-desc" style="margin-top: -1rem">员工自行购买的订单是否计入直推订单计算佣金</div>-->
<!--      </el-form-item>-->

      <el-divider></el-divider>
<!--      <el-form-item label="到账模式">-->
<!--&lt;!&ndash;        <el-radio v-model="form.invite_award_cash_func" :label="1">余额提现</el-radio>&ndash;&gt;-->
<!--        <el-radio v-model="form.invite_award_cash_func" :label="2">微信自动打款</el-radio>-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
<!--    <el-dialog title="指定区域中心点" destroy-on-close :visible.sync="area_limit_box">-->
<!--      <add @point="area_limit_point" :lat="form.area_limit_lat" :long="form.area_limit_long"></add>-->
<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-button @click="area_limit_box = false">取 消</el-button>-->
<!--        <el-button type="primary" @click="area_limit_box = false">确 定</el-button>-->
<!--      </div>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
import yUploadImg from "@/components/y_upload_img";
// import add from "@/components/qqmap/add";
export default {
  name: "basic_mode5",
  components:{
    yUploadImg,
    // add
  },
  data(){
    return{
      area_limit_box:false,
      posterShow:false,
      form:{
        must_phone:1,
        must_follow:1,
        wx_account_qrcode:"",
        area_limit:1,
        area_limit_func:2,
        area_limit_cities:"",
        area_limit_lat:"",
        area_limit_long:"",
        area_limit_radius:5,
        area_limit_url:"",
        must_from_share:1,
        tag_first_clerk:1,
        clerk_buy_award:1,
        service_clerk_award:0,
        award_limit:0,
        wxapp_auto_sub:false,
      },
      edit_id:0,
      loading:false,
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load()
    }
  },
  methods:{
    area_limit_point(lat,long){
      this.form.area_limit_lat = lat+'';
      this.form.area_limit_long = long+'';
    },
    load(){
      this.loading = true;
      this.$api.active.one({id:this.edit_id}).then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
    onSubmit(){
      this.form.area_limit_radius = parseInt(this.form.area_limit_radius);
      this.form.service_clerk_award = parseFloat(this.form.service_clerk_award);
      this.$api.active.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>