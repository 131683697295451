<template>
  <div>
    <div class="flex-def flex-zEnd" style="margin-bottom: 1rem">
      <el-button @click="dialogTableVisible=true" type="primary" size="medium">添加</el-button>
      <el-dialog title="添加卡券" :visible.sync="dialogTableVisible">
        <div class="flex-def flex-zCenter">
          <el-form style="width: 100%">
            <el-form-item label="选择商户">
              <yChooseMerchant v-model="search_merchant_id" style="width: 100%"></yChooseMerchant>
            </el-form-item>
          </el-form>
        </div>
        <el-table :data="gridData">
          <el-table-column property="id" label="编号" width="60"></el-table-column>
          <el-table-column property="title" label="卡券名称"></el-table-column>
          <el-table-column label="操作" width="80">
            <template #default="s">
              <el-button @click="chooseCoupon(s.row.id)" size="mini">添加</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <el-table v-loading="loading" :data="tableData" border style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column label="商户">
        <template #default="s">
          <span v-if="s.row.merchant">{{s.row.merchant.title}}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡券">
        <template #default="s">
          <span v-if="s.row.coupon">{{s.row.coupon.title}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">发放中</el-table-column>
      <el-table-column width="80" label="操作">
        <template #default="s">
          <el-button @click="delCoupon(s.row.id)" size="mini">移除</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
import yChooseMerchant from "@/components/y_choose_merchant";
export default {
  name: "edit_coupon",
  components:{yChooseMerchant},
  data(){
    return{
      dialogTableVisible:false,
      search_merchant_id:0,
      loading:false,
      gridData:[],
      tableData:[],
      total:0,
      page:1,
    }
  },
  watch:{
    search_merchant_id(){
      this.$api.coupon.page({
        page:1,
        merchant_id:this.search_merchant_id
      }).then(res=>{
        this.gridData = res.list;
      })
    },
  },
  mounted() {
    this.load();
  },
  methods:{
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.loading = true;
      this.$api.active.couponPage({
        active_id:parseInt(this.$route.query.id),
        page:this.page
      }).then((res)=>{
        this.loading = false;
        this.tableData = res.list;
        this.total = res.total;
      })
    },
    delCoupon(id){
      this.$api.active.couponDel({
        active_id:parseInt(this.$route.query.id),
        id
      }).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    chooseCoupon(coupon_id){
      this.$api.active.couponAdd({
        active_id:parseInt(this.$route.query.id),
        coupon_id:coupon_id
      }).then(()=>{
        this.$message.success("操作成功");
        this.dialogTableVisible = false;
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>