<template>
  <div>
    <div class="flex-def flex-zBetween" style="margin-bottom: 1rem">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="用户昵称">
          <el-input v-model="searchForm.nickname" size="medium" placeholder="输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="卡券状态">
          <el-select v-model="searchForm.status">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="未核销" :value="1"></el-option>
            <el-option label="已核销" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="领取时间">
          <el-date-picker
              v-model="searchForm.get_between"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="所属商户">
          <y_choose_merchant v-model="searchForm.merchant_id"></y_choose_merchant>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button @click="load" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
            <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <div>
        <el-button @click="exp" type="primary" size="medium">导出</el-button>
      </div>

    </div>
    <el-table :data="list" border :row-class-name="tableRowClassName" style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column label="昵称" width="120">
        <template #default="s">{{s.row.member.nickname}}</template>
      </el-table-column>
      <el-table-column label="头像" width="60" align="center">
        <template #default="scope">
          <el-avatar shape="square" size="small" :src="scope.row.member.avatar | tomedia"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column label="所属商家" width="120">
        <template #default="s">{{s.row.merchant.title}}</template>
      </el-table-column>
      <el-table-column prop="title" label="卡券名称" width="120"></el-table-column>
      <el-table-column prop="total_num" label="可核销总量" width="120"></el-table-column>
      <el-table-column label="已核销" width="120">
        <template #default="s">{{s.row.total_num-s.row.last_use_num}}</template>
      </el-table-column>
      <el-table-column prop="last_use_num" label="未核销" width="120"></el-table-column>
      <el-table-column prop="fee" label="卡券面值" width="120"></el-table-column>
      <el-table-column prop="use_limit" label="核销限制" width="120"></el-table-column>
      <el-table-column prop="tips" label="使用门栏" width="120"></el-table-column>
      <el-table-column prop="create_time" label="领取时间" width="180">
        <template #default="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
import y_choose_merchant from "@/components/y_choose_merchant"
export default {
  name: "data_order",
  components:{
    y_choose_merchant,
  },
  data(){
    return{
      page:1,
      size:15,
      total:0,
      list:[],
      searchForm:{
        nickname:"",
        status:0,
        get_between:[],
        merchant_id:0,
      },
    }
  },
  mounted() {
    this.load()
  },
  methods:{
    tableRowClassName({rowIndex}) {
      if (rowIndex%2){
        return "warning-row"
      }else {
        return 'success-row';
      }
    },
    exp(){
      this.$api.active.recordCouponExport({
        active_id: parseInt(this.$route.query.id),
        nickname : this.searchForm.nickname,
        status:this.searchForm.status,
        get_between:this.searchForm.get_between,
        merchant_id:this.searchForm.merchant_id,
      }).then(res=>{
        location.href = res;
      })
    },
    resetSearch(){
      this.searchForm = {
        nickname:"",
        status:0,
        get_between:[],
        merchant_id:0,
      }
      this.load();
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$api.active.recordCoupon({
        active_id:parseInt(this.$route.query.id),
        nickname : this.searchForm.nickname,
        status:this.searchForm.status,
        get_between:this.searchForm.get_between,
        merchant_id:this.searchForm.merchant_id,
        page : this.page
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  }
}
</script>

<style scoped>

</style>