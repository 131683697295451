<template>
  <div>
    <el-input :value="showvalue" disabled>
      <template #append>
        <el-button @click="dialog = true">选择卡券</el-button>
      </template>
    </el-input>
    <el-dialog title="选择卡券" append-to-body :visible.sync="dialog" width="48rem" @close="close">
      <div class="flex-def flex-zCenter">
        <el-form style="width: 100%">
          <el-form-item label="选择商户">
            <yChooseMerchant v-model="search_merchant_id" style="width: 100%"></yChooseMerchant>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="list">
        <el-table-column property="id" label="编号" width="60"></el-table-column>
        <el-table-column property="title" label="卡券名称"></el-table-column>
        <el-table-column label="操作" width="80">
          <template #default="s">
            <el-button @click="chooseCoupon(s.row)" size="mini">选中</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import yChooseMerchant from "@/components/y_choose_merchant";

export default {
  name: "y_choose_coupon",
  components:{
    yChooseMerchant,
  },
  model:{
    prop:"modelval",
    event:'change'
  },
  props:{
    modelval: {
      type:Number,
      default:0
    }
  },
  data(){
    return{
      value: this.modelval,
      showvalue:"",
      dialog:false,
      search_merchant_id:0,
      list:[],
    }
  },
  mounted() {
    this.loadValue();
  },
  watch:{
    modelval(){
      this.loadValue();
      this.value = this.modelval;
    },
    value(e){
      this.$emit('change',e);
    },
    search_merchant_id(){
      if(this.search_merchant_id === 0){
        return
      }
      this.$api.coupon.page({
        page:1,
        merchant_id:this.search_merchant_id
      }).then(res=>{
        this.list = res.list;
      })
    },
  },
  methods:{
    loadValue(){
      if(this.modelval === 0){
        return;
      }
      this.$api.coupon.one({id:this.modelval}).then(res=>{
        this.showvalue = res.title;
      });
    },
    close(){
      this.search_merchant_id = 0;
      this.list = [];
      this.dialog = false;
    },
    chooseCoupon(item){
      this.showvalue = item.title;
      this.value = item.id;
      this.close();
    }
  }
}
</script>

<style scoped>

</style>