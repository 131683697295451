<template>
  <YTabs active-line>
    <YTabPane label="基础配置" name="basic">
      <el-card>
        <basic_mode5 v-if="active.mode === 5"></basic_mode5>
        <basic_mode6 v-if="active.mode === 6"></basic_mode6>
        <basic v-if="![5,6].includes(active.mode)"></basic>
      </el-card>
    </YTabPane>
    <template v-if="![5,6].includes(active.mode)">
      <YTabPane  label="邀请订单奖励" name="invite">
        <el-card>
        <invite_default v-if="!isClassicSpecial(active.mode)"></invite_default>
        <invite_mode3 v-if="isClassicSpecial(active.mode)"></invite_mode3>
        </el-card>
      </YTabPane>
      <YTabPane v-if="isVideoSpecial(active.mode)" label="视频页面内容" name="video_page">
        <el-card>
        <video_page></video_page>
        </el-card>
      </YTabPane>
      <YTabPane v-if="modeHasTurntable(active.mode)" label="大转盘" name="turntable">
        <el-card>
        <turntable></turntable>
        </el-card>
      </YTabPane>
      <YTabPane v-if="modeHasDrawGird(active.mode)" label="九宫格" name="draw_gird">
        <el-card>
        <draw_grid></draw_grid>
        </el-card>
      </YTabPane>
      <YTabPane v-if="modeHasDrawBox(active.mode)" label="盲盒" name="draw_box">
        <el-card>
          <draw_box></draw_box>
        </el-card>
      </YTabPane>
      <YTabPane v-if="modeHasShareGift(active.mode)" label="推荐礼品" name="share_gift">
        <el-card>
          <share_gift></share_gift>
        </el-card>
      </YTabPane>
      <YTabPane label="分享红包" name="share_red">
        <el-card>
          <share_red_packet></share_red_packet>
        </el-card>
      </YTabPane>
    </template>
    <template v-if="active.mode === 5">
      <YTabPane label="领券配置" name="mode5_rule">
        <el-card>
        <mode5_rule></mode5_rule>
        </el-card>
      </YTabPane>
      <YTabPane label="商家余额" name="mode5_merchant">
        <el-card>
          <mode5_merchant></mode5_merchant>
        </el-card>
      </YTabPane>
    </template>
  </YTabs>
<!--  <el-tabs type="border-card" value="basic">-->
<!--    <el-tab-pane label="基础配置" name="basic">-->
<!--      <basic_mode5 v-if="active.mode === 5"></basic_mode5>-->
<!--      <basic_mode6 v-if="active.mode === 6"></basic_mode6>-->
<!--      <basic v-if="![5,6].includes(active.mode)"></basic>-->
<!--    </el-tab-pane>-->
<!--    <template v-if="![5,6].includes(active.mode)">-->
<!--      <el-tab-pane  label="邀请订单奖励" name="invite">-->
<!--        <invite_default v-if="!isClassicSpecial(active.mode)"></invite_default>-->
<!--        <invite_mode3 v-if="isClassicSpecial(active.mode)"></invite_mode3>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane v-if="isVideoSpecial(active.mode)" label="视频页面内容" name="video_page">-->
<!--        <video_page></video_page>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane v-if="modeHasTurntable(active.mode)" label="大转盘" name="turntable">-->
<!--        <turntable></turntable>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane v-if="modeHasDrawGird(active.mode)" label="九宫格" name="draw_gird">-->
<!--        <draw_grid></draw_grid>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane v-if="modeHasDrawBox(active.mode)" label="盲盒" name="draw_box">-->
<!--        <draw_box></draw_box>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane v-if="modeHasShareGift(active.mode)" label="推荐礼品" name="share_gift">-->
<!--        <share_gift></share_gift>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="分享红包" name="share_red">-->
<!--        <share_red_packet></share_red_packet>-->
<!--      </el-tab-pane>-->
<!--    </template>-->
<!--    <template v-if="active.mode === 5">-->
<!--      <el-tab-pane label="领券配置" name="mode5_rule">-->
<!--        <mode5_rule></mode5_rule>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="商家余额" name="mode5_merchant">-->
<!--        <mode5_merchant></mode5_merchant>-->
<!--      </el-tab-pane>-->
<!--    </template>-->
<!--  </el-tabs>-->
</template>

<script>
import basic from "@/view/active/edit/edit_rule/basic";
import invite_default from "@/view/active/edit/edit_rule/invite_default";
import invite_mode3 from "@/view/active/edit/edit_rule/invite_mode3";
import turntable from "@/view/active/edit/edit_rule/turntable";
import share_gift from "@/view/active/edit/edit_rule/share_gift";
import share_red_packet from "@/view/active/edit/edit_rule/share_red_packet";
import draw_grid from "@/view/active/edit/edit_rule/draw_grid";
import draw_box from "@/view/active/edit/edit_rule/draw_box";
import video_page from "@/view/active/edit/edit_rule/video_page";
import basic_mode5 from "@/view/active/edit/edit_rule/basic_mode5";
import basic_mode6 from "@/view/active/edit/edit_rule/basic_mode6";
import mode5_rule from "@/view/active/edit/edit_rule/mode5_rule";
import mode5_merchant from "@/view/active/edit/edit_rule/mode5_merchant";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";
export default {
  name: "edit_rule",
  components:{
    YTabPane,
    YTabs,
    basic,turntable,share_gift,share_red_packet,draw_grid,draw_box,
    video_page,invite_default,invite_mode3,
    basic_mode5,basic_mode6,
    mode5_rule,mode5_merchant
  },
  props:{
    active:{
      type:Object,
      default(){
        return{
          mode:0,
        }
      }
    }
  },
  data(){
    return{

    }
  },
  mounted() {

  },
  methods:{
    isClassicSpecial(mode){
      return parseInt(mode) === 3
    },
    isVideoSpecial(mode){
      return parseInt(mode) === 2
    },
    modeHasDrawGird(mode){
      return [1,3,4].includes(parseInt(mode))
    },
    modeHasDrawBox(mode){
      return [1,3,4].includes(parseInt(mode))
    },
    modeHasTurntable(mode){
      return [1,3,4].includes(parseInt(mode))
    },
    modeHasShareGift(mode){
      return [1,3,4].includes(parseInt(mode))
    }
  }
}
</script>

<style scoped>

</style>