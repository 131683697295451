<template>
  <y_layout>
    <YTabs ref="yTabs" v-loading="loading" v-model="active_tab">
      <YTabPane name="basic" label="基础设置" :index="1">
        <el-card>
          <edit_basic v-if="active.id > 0" :active_mode="active.mode"></edit_basic>
        </el-card>
      </YTabPane>
      <YTabPane :index="2" :label="active.mode === 2 ? '活动详情页面设计' : '页面设计'" v-if="[1,2,3,4,5,6].includes(active.mode)" name="design">
        <el-card>
        <editor :active_mode="parseInt(active.mode)" v-if="active_tab === 'design'"></editor>
        </el-card>
      </YTabPane>
      <YTabPane :index="3" v-if="active.mode === 6" name="cdkey" label="激活码">
        <template v-if="active_tab === 'cdkey'">
          <mode6_cdkey></mode6_cdkey>
        </template>
      </YTabPane>
      <YTabPane :index="4" name="coupon" label="卡券设置">
        <el-card>
        <template v-if="active_tab === 'coupon'">
          <edit_coupon_mode5 v-if="active.mode === 5"></edit_coupon_mode5>
          <edit_coupon v-else></edit_coupon>
        </template>
        </el-card>
      </YTabPane>
      <YTabPane :index="5" name="rule" label="规则设置">
        <edit_rule :active="active" v-if="active_tab === 'rule'"></edit_rule>
      </YTabPane>
      <YTabPane :index="6" name="form" v-if="active.mode !== 5" label="表单设置">
        <el-card>
          <edit_form v-if="active_tab === 'form'"></edit_form>
        </el-card>
      </YTabPane>
      <YTabPane :index="7" v-if="active.id > 0" name="share" label="分享设置">
        <edit_share v-if="active_tab === 'share'"></edit_share>
      </YTabPane>
      <YTabPane :index="8" name="sign_code" v-if="active.mode !== 5" label="入群口令">
        <edit_code v-if="active_tab === 'sign_code'"></edit_code>
      </YTabPane>
      <YTabPane card :index="9" name="fake" v-if="active.mode !== 5" label="虚拟数据">
        <edit_fake v-if="active_tab === 'fake'"></edit_fake>
      </YTabPane>
      <YTabPane :index="10" name="entry" label="活动链接">
        <edit_entry></edit_entry>
      </YTabPane>
      <YTabPane :index="11" name="record" label="活动数据">
        <record v-if="active_tab === 'record'"></record>
      </YTabPane>
    </YTabs>
<!--    <el-tabs v-loading="loading" v-model="active_tab" type="border-card" style="min-width: 90rem">-->
<!--      <el-tab-pane name="basic">-->
<!--        <span slot="label" style="font-weight: 600">基础设置</span>-->
<!--        <edit_basic v-if="active.id > 0" :active_mode="active.mode"></edit_basic>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane v-if="[1,2,3,4,5,6].includes(active.mode)" name="design">-->
<!--        <span slot="label" style="font-weight: 600">{{active.mode === 2 ? '活动详情' : ''}}页面设计</span>-->
<!--        <editor :active_mode="parseInt(active.mode)" v-if="active_tab === 'design'"></editor>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane v-if="active.mode === 6" name="cdkey">-->
<!--        <span slot="label" style="font-weight: 600">激活码</span>-->
<!--        <template v-if="active_tab === 'cdkey'">-->
<!--          <mode6_cdkey></mode6_cdkey>-->
<!--        </template>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane name="coupon">-->
<!--        <span slot="label" style="font-weight: 600">卡券设置</span>-->
<!--        <template v-if="active_tab === 'coupon'">-->
<!--          <edit_coupon_mode5 v-if="active.mode === 5"></edit_coupon_mode5>-->
<!--          <edit_coupon v-else></edit_coupon>-->
<!--        </template>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane name="rule">-->
<!--        <span slot="label" style="font-weight: 600">规则设置</span>-->
<!--        <edit_rule :active="active" v-if="active_tab === 'rule'"></edit_rule>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane name="form" v-if="active.mode !== 5">-->
<!--        <span slot="label" style="font-weight: 600">表单设置</span>-->
<!--        <edit_form v-if="active_tab === 'form'"></edit_form>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane v-if="active.id > 0" name="share">-->
<!--        <span slot="label" style="font-weight: 600">分享设置</span>-->
<!--        <edit_share v-if="active_tab === 'share'"></edit_share>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane name="sign" v-if="active.mode !== 5">-->
<!--        <span slot="label" style="font-weight: 600">入群口令</span>-->
<!--        <edit_code v-if="active_tab === 'sign'"></edit_code>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane name="fake" v-if="active.mode !== 5">-->
<!--        <span slot="label" style="font-weight: 600">虚拟数据</span>-->
<!--        <edit_fake v-if="active_tab === 'fake'"></edit_fake>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane name="entry">-->
<!--        <span slot="label" style="font-weight: 600">活动链接</span>-->
<!--        <edit_entry></edit_entry>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane name="record">-->
<!--        <span slot="label" style="font-weight: 600">活动数据</span>-->
<!--        <record v-if="active_tab === 'record'"></record>-->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import edit_basic from "@/view/active/edit/edit_basic";
import editor from "@/view/active/edit/design/editor";
import edit_coupon from "@/view/active/edit/edit_coupon";
import edit_form from "@/view/active/edit/edit_form";
import edit_share from "@/view/active/edit/edit_share";
import edit_code from "@/view/active/edit/edit_code";
import edit_fake from "@/view/active/edit/edit_fake";
import edit_entry from "@/view/active/edit/edit_entry";
import edit_rule from "@/view/active/edit/edit_rule";
import record from "@/view/active/record";
import edit_coupon_mode5 from "@/view/active/edit/edit_coupon_mode5";
import mode6_cdkey from "@/view/active/edit/mode6_cdkey";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";
export default {
  name: "edit",
  components:{
    YTabPane,
    YTabs,
    y_layout,
    edit_basic,
    editor,
    edit_coupon,
    edit_form,
    edit_share,
    edit_code,
    edit_fake,
    edit_entry,
    record,
    edit_rule,
    edit_coupon_mode5,
    mode6_cdkey,
  },
  data(){
    return{
      loading:false,
      active_tab:"basic",
      active_id:0,
      active:{
        mode:0,
      }
    }
  },
  mounted() {
    let tab = this.$route.query.tab;
    this.active_tab = tab || "basic";
    if(this.$route.query.id){
      this.active_id = parseInt(this.$route.query.id);
      this.load()
    }
  },
  methods:{
    load(){
      this.loading = true;
      this.$api.active.one({id:this.active_id}).then(res=>{
        this.active = res;
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>