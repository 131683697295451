<template>
  <div style="padding: 2rem">
    <div class="header-title">页面管理</div>
    <div style="margin-top: 1rem;">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">背景色</span></el-col>
        <el-col :span="19">
          <y_color_choose @change="onChange" v-model="bg_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">背景音乐</span></el-col>
        <el-col :span="19">
          <y_upload_audio_box @change="onAudioChange" v-model="bg_audio"></y_upload_audio_box>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import y_color_choose from "@/components/y_color_choose";
import y_upload_audio_box from "@/components/y_upload_audio";
export default {
  name: "con_page",
  components:{
    y_color_choose,
    y_upload_audio_box,
  },
  props:{
    bgColor:{
      type:String,
      default:"",
    },
    bgAudio:{
      type:String,
      default: ""
    }
  },
  data(){
    return{
      bg_color:this.bgColor,
      bg_audio:this.bgAudio
    }
  },
  watch:{
    bgColor(){
      this.bg_color = this.bgColor;
    },
    bgAudio(){
      this.bg_audio = this.bgAudio;
    }
  },
  methods:{
    onChange(){
      this.$emit("bg-color",this.bg_color);
    },
    onAudioChange(){
      this.$emit("bg-audio",this.bg_audio);
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
.el-row{
  margin-bottom: 1rem;
}
</style>
