<template>
  <div>
    <el-form v-loading="loading" ref="form" :model="form" label-width="120px" style="width: 80rem">
      <el-form-item label="每人共可领券">
        <el-input-number size="mini" v-model="form.member_can_get_num"></el-input-number>
        次
      </el-form-item>
      <el-form-item label="每人每天可领券">
        <el-input-number size="mini" v-model="form.member_day_can_get_num"></el-input-number>
        次
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "mode5_rule",
  data() {
    return {
      form: {
        member_can_get_num: 0,
        member_day_can_get_num: 0,
      },
      edit_id: 0,
      loading: false,
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.edit_id = parseInt(this.$route.query.id);
      this.load()
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.$api.plugin.free.extendFetch({
        active_id: this.edit_id
      }).then(res => {
        this.form = res;
        this.loading = false;
      })
    },
    onSubmit() {
      this.$api.plugin.free.extendUpdate(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>