<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="启用">
        <el-radio v-model="form.share_red_packet" :label="2">开启</el-radio>
        <el-radio v-model="form.share_red_packet" :label="1">关闭</el-radio>
      </el-form-item>
      <el-form-item label="规则">
        <el-input v-model="form.share_red_packet_num" style="width: 18rem">
          <template #prepend>每分享</template>
          <template #append>人首次打开活动页面</template>
        </el-input>
        <el-input v-model="form.share_red_packet_award" style="width: 18rem">
          <template #prepend>即获取</template>
          <template #append>元红包</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="onSubmit" type="primary">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "share_red_packet",
  components:{

  },
  data(){
    return{
      form:{
        share_red_packet:1,
        share_red_packet_num:0,
        share_red_packet_award:0,
      },
      edit_id:0,
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
    }
  },
  methods:{
    load(){
      this.$api.active.one({id:this.edit_id}).then(res=>{
        this.form = res;
      })
    },
    onSubmit(){
      this.form.share_red_packet_num = parseInt(this.form.share_red_packet_num);
      this.form.share_red_packet_award = parseFloat(this.form.share_red_packet_award);
      this.$api.active.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>