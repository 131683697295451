<template>
  <YTabs active-line v-loading="loading" v-if="active.id > 0">
    <YTabPane card :index="1" name="order" v-if="active.mode !== 5" label="订单数据">
      <record_order :active="active"></record_order>
    </YTabPane>
    <YTabPane card :index="2" name="group" v-if="active.mode == 4" label="拼团数据">
      <group_buy></group_buy>
    </YTabPane>
    <YTabPane card :index="3" name="coupon" label="卡券数据">
      <record_coupon></record_coupon>
    </YTabPane>
    <YTabPane card :index="4" name="coupon-cashier" label="卡券核销明细">
      <coupon_cashier></coupon_cashier>
    </YTabPane>
    <YTabPane card :index="5" name="award" v-if="active.mode != 6" label="奖励数据">
      <record_award :active="active"></record_award>
    </YTabPane>
    <YTabPane card :index="6" name="award-hand" v-if="active.mode != 6" label="奖励补发" >
      <record_award_hand :active="active"></record_award_hand>
    </YTabPane>
    <YTabPane card :index="7" name="visit" label="访问数据">
      <record_visit></record_visit>
    </YTabPane>
    <YTabPane card :index="8" name="merchant-share" v-if="active.mode != 6" label="商家分享">
      <record_m_share></record_m_share>
    </YTabPane>
  </YTabs>
<!--  <el-tabs v-loading="loading" type="border-card">-->
<!--    <el-tab-pane v-if="active.mode !== 5" label="订单数据">-->
<!--      <record_order :active="active"></record_order>-->
<!--    </el-tab-pane>-->
<!--    <el-tab-pane v-if="active.mode == 4" label="拼团数据">-->
<!--      <group_buy></group_buy>-->
<!--    </el-tab-pane>-->
<!--    <el-tab-pane label="卡券数据">-->
<!--      <record_coupon></record_coupon>-->
<!--    </el-tab-pane>-->
<!--    <el-tab-pane label="卡券核销明细">-->
<!--      <coupon_cashier></coupon_cashier>-->
<!--    </el-tab-pane>-->
<!--    <el-tab-pane v-if="active.mode != 6" label="奖励数据">-->
<!--      <record_award :active="active"></record_award>-->
<!--    </el-tab-pane>-->
<!--    <el-tab-pane v-if="active.mode != 6" label="奖励补发" >-->
<!--      <record_award_hand :active="active"></record_award_hand>-->
<!--    </el-tab-pane>-->
<!--    <el-tab-pane label="访问数据">-->
<!--      <record_visit></record_visit>-->
<!--    </el-tab-pane>-->
<!--    <el-tab-pane v-if="active.mode != 6" label="商家分享">-->
<!--      <record_m_share></record_m_share>-->
<!--    </el-tab-pane>-->
<!--  </el-tabs>-->
</template>

<script>
import record_order from "@/view/active/record/order";
import record_coupon from "@/view/active/record/coupon";
import coupon_cashier from "@/view/active/record/coupon_cashier";
import record_award from "@/view/active/record/award";
import record_award_hand from "@/view/active/record/award_hand";
import record_visit from "@/view/active/record/visit";
import record_m_share from "@/view/active/record/m_share";
import group_buy from "@/view/active/record/group_buy";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";
export default {
  name: "record",
  components:{
    YTabPane,
    YTabs,
    group_buy,
    record_order,
    record_coupon,
    record_award,
    record_award_hand,
    record_visit,
    record_m_share,
    coupon_cashier
  },
  data(){
    return{
      loading:false,
      active:{
        mode:0,
      }
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.active_id = parseInt(this.$route.query.id);
      this.load()
    }
  },
  methods:{
    load(){
      this.loading = true;
      this.$api.active.one({id:this.active_id}).then(res=>{
        this.active = res;
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>