<template>
  <div>
    <el-form ref="form" :model="form">
      <el-form-item>
        <div class="flex-def flex-cCenter flex-zBetween">
          <div class="flex-def flex-cCenter">
            <div style="width: 180px">
              <div style="font-weight: 600;">抽奖次数获取方式</div>
              <div class="y-desc" style="line-height: 1rem">说明：三种抽奖次数获取方式可选一种或多种，其中推荐和分享可累计多次获取抽奖次数</div>
            </div>
            <div style="margin: 0 2rem;color: #909399">
              <div>
                <el-checkbox v-model="form.draw_box_func_scope" label="spread">推荐购买</el-checkbox>
                每推荐
                <el-input-number size="mini" style="width: 8rem" v-model="form.draw_box_need_spread"></el-input-number>
                人成功购买
                即获取
                <el-input-number size="mini" style="width: 8rem"
                                 v-model="form.draw_box_spread_get_num"></el-input-number>
                次抽奖次数
              </div>
              <div>
                <el-checkbox v-model="form.draw_box_func_scope" label="buy">自己购买</el-checkbox>
                可获得
                <el-input-number size="mini" style="width: 8rem" v-model="form.draw_box_buy_get_num"></el-input-number>
                次抽奖次数
              </div>
              <div>
                <el-checkbox v-model="form.draw_box_func_scope" label="share">分享活动</el-checkbox>
                每分享
                <el-input-number size="mini" style="width: 8rem" v-model="form.draw_box_need_share"></el-input-number>
                人首次访问
                即获取
                <el-input-number size="mini" style="width: 8rem"
                                 v-model="form.draw_box_share_get_num"></el-input-number>
                次抽奖次数
              </div>
            </div>
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </div>
          <div>
            <el-button @click="prizeBoxShow=true" type="primary">添加奖品</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column prop="title" label="奖品名称"></el-table-column>
      <el-table-column label="图标">
        <template #default="s"><img :src="s.row.pic | tomedia" style="width: 3rem;height: 3rem;border-radius: .5rem"
                                    alt=""></template>
      </el-table-column>
      <el-table-column label="类型">
        <template #default="s">{{ elPrizeType(s.row.type) }}</template>
      </el-table-column>
      <el-table-column label="详情">
        <template #default="s">{{ elPrizeContent(s.row) }}</template>
      </el-table-column>
      <el-table-column prop="chance" label="中奖概率"></el-table-column>
      <el-table-column prop="grant_day_limit" label="单日可中奖次数"></el-table-column>
      <el-table-column prop="grant_active_limit" label="可中奖次数总量"></el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template #default="s">
          <el-button @click="prizeFormEdit(s.row)" size="mini" type="primary">编辑</el-button>
          <el-button @click="prizeDel(s.row.id)" size="mini" type="danger">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加奖品" :visible.sync="prizeBoxShow" width="48rem" @close="prizeFormClose">
      <div style="width: 95%">
        <el-form :model="prizeForm" label-width="5rem">
          <el-form-item label="名称">
            <el-input v-model="prizeForm.title"></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-radio-group v-model="prizeForm.type">
              <el-radio border :label="1">无奖品</el-radio>
              <el-radio border :label="2">卡券</el-radio>
              <el-radio border :label="3">红包</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="图标" v-if="prizeForm.type !== 2">
            <y_upload_img v-model="prizeForm.pic"></y_upload_img>
          </el-form-item>
          <el-form-item v-if="prizeForm.type === 3" label="红包金额">
            <el-input v-model="prizeForm.red_paket">
              <template #append>元</template>
            </el-input>
          </el-form-item>
          <el-form-item v-if="prizeForm.type === 2" label="卡券">
            <y_choose_coupon v-model="prizeForm.coupon_id"></y_choose_coupon>
          </el-form-item>
          <el-form-item label="中奖概率">
            <el-input v-model="prizeForm.chance"></el-input>
            <div class="y-desc">为0时此奖品不出奖</div>
          </el-form-item>
          <el-form-item label="单日可中奖次数">
            <el-input v-model="prizeForm.grant_day_limit"></el-input>
            <div class="y-desc">为0时不限制单日中奖次数</div>
          </el-form-item>
          <el-form-item label="可中奖次数总量">
            <el-input v-model="prizeForm.grant_active_limit"></el-input>
            <div class="y-desc">为0时不限制可中奖次数</div>
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="prizeFormSubmit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import y_choose_coupon from "@/components/y_choose_coupon";
import y_upload_img from "@/components/y_upload_img";

export default {
  name: "draw_box",
  components: {
    y_choose_coupon,
    y_upload_img
  },
  data() {
    return {
      prizeBoxShow: false,
      prizeForm: {
        type: 1,
        coupon_id: 0,
        pic: "",
        title: "",
        red_paket: 0,
        chance: 0,
        grant_day_limit: 0,
        grant_active_limit: 0,
      },
      form: {
        draw_box_need_spread: 0,
        draw_box_spread_get_num: 0,

        draw_box_func_scope: [],
        draw_box_share_get_num: 0,
        draw_box_need_share: 0,
        draw_box_buy_get_num: 0
      },
      list: [],
      edit_id: 0,
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
      this.prizeAll();
    }
  },
  methods: {
    elPrizeContent(item) {
      if (item.type === 3) {
        return "红包:" + item.red_paket + "元";
      } else if (item.type === 2) {
        return "卡券：" + (item.coupon ? item.coupon.title : "");
      }
      return "";
    },
    elPrizeType(type) {
      let str = "";
      switch (parseInt(type)) {
        case 1:
          str = "无奖品";
          break;
        case 2:
          str = "卡券";
          break;
        case 3:
          str = "红包"
          break
      }
      return str;
    },
    prizeAll() {
      this.$api.active.drawBoxAll({active_id: this.edit_id}).then(res => {
        this.list = res;
      })
    },
    prizeDel(id) {
      this.$api.active.drawBoxDel({
        active_id: this.edit_id,
        id
      }).then(() => {
        this.$message.success("操作成功");
        this.prizeAll();
      })
    },
    prizeFormSubmit() {
      this.prizeForm.active_id = this.edit_id;
      this.prizeForm.red_paket = parseFloat(this.prizeForm.red_paket);
      this.prizeForm.chance = parseInt(this.prizeForm.chance);
      this.prizeForm.grant_day_limit = parseInt(this.prizeForm.grant_day_limit);
      this.prizeForm.grant_active_limit = parseInt(this.prizeForm.grant_active_limit);
      this.$api.active.drawBoxEdit(this.prizeForm).then(() => {
        this.$message.success("操作成功");
        this.prizeFormClose();
        this.prizeAll();
      })
    },
    prizeFormEdit(item) {
      this.prizeForm = item
      this.prizeBoxShow = true;
    },
    prizeFormClose() {
      this.prizeForm = {
        type: 1,
        coupon_id: 0,
        title: "",
        red_paket: 0,
        chance: 0,
        grant_day_limit: 0,
        grant_active_limit: 0,
      }
      this.prizeBoxShow = false;
    },
    load() {
      this.$api.active.one({id: this.edit_id}).then(res => {
        res.draw_box_func_scope = res.draw_box_func_scope ? res.draw_box_func_scope.split(",") : [];
        this.form = res;
      })
    },
    onSubmit() {
      let post = {
        ...this.form
      }
      post.draw_box_func_scope = post.draw_box_func_scope.join(",");
      this.$api.active.update(post).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>