<template>
  <div>
    <el-container v-loading="loading" style="height: 42rem">
      <el-aside class="y-aside" width="310px">
        <lCon @con_add="conAdd" :coms="coms" :con_arr="con_arr"></lCon>
      </el-aside>
      <el-main style="min-width: 45rem;background-color: #f8f8f8;height: 42rem">
        <div style="position: relative;float: right;margin-left: 4rem;width: 5rem;height: 1rem">
          <div style="position: fixed;top: 20%;font-size: 12px;width: 5rem;text-align: center">
            <div @click="active_r_con_choose(-2)" :class="active_r_con === -2 ? 'btn-active' : ''" class="panel-shadow r-con-field-btn">页面管理</div>
            <div @click="active_r_con_choose(-1)" :class="active_r_con === -1 ? 'btn-active' : ''" class="panel-shadow r-con-field-btn" style="margin-top: 10px">组件管理</div>
          </div>
        </div>
        <div class="flex-def flex-zCenter flex-cCenter">
          <div style="position: relative;width: 375px;">
            <div style="text-align: center;line-height: 44px;font-size: 1rem;background-color: #ededed">页面标题</div>
            <iframe ref="iframe" id="editor" frameborder="0" class="panel-shadow" scrolling="no" style="overflow: hidden;"
                    width="375px" :height="out_height + 'px'" :src="`./${publicPath}d/design.html?${$store.state.setting.uni_attachment_url[$route.params.uni_acid]}`"></iframe>
<!--              width="375px" :height="out_height + 'px'" :src="`http://localhost:8081/?${$store.state.setting.attachment_url}`"></iframe>-->

<!--                           todo::完工后替换     -->

            <div class="con-panel" @dragenter="comenter" @dragover="comsmove" @drop="drop">
              <div style="height: 44px"></div>
              <div @click="active_r_con_choose(key)" v-for="(item,key) in coms" :key="key" :style="floatArr.includes(item.name) ? 'position:fixed;top:20%;' : ''+`height:${item.height}px`"
                   @dragover="addcommove" @dragenter="addcomenter" @dragleave="addcomleave"
                   :data-name="item.name" :data-index="key" class="con-box">
                <div :style="floatArr.includes(item.name) ? floatStyle[item.name]+'top:0;right:100%;margin-left:unset':''" :class="active_r_con === key ? 'btn-body-active' : ''" v-if="item.title" class="flex-def flex-cCenter con-body">
                  <div v-if="!floatArr.includes(item.name)" class="con-left"></div>
                  <div class="panel-shadow con-title" >{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <el-aside width="400px">
        <div class="flex-def flex-zCenter flex-cCenter">
          <el-button @click="save"  type="primary" size="mini">保存页面设计</el-button>
          <el-button @click="makePic" size="mini">另存为模版</el-button>
        </div>
        <con-com v-if="active_r_con === -1" @change="sortComs" :coms="coms"></con-com>
        <con-page v-if="active_r_con === -2" :bg-audio="active_tpl_info.bg_audio" @bg-audio="infoBgAudioChange" :bg-color="active_tpl_info.bg_color" @bg-color="infoBgColorChange"></con-page>
        <conComEdit @del="comsConfDel" @change="comsConfChange" v-if="![-1,-2].includes(active_r_con)" :coms="active_r_con_coms"></conComEdit>
      </el-aside>
    </el-container>
    <el-dialog title="另存为模版" :visible.sync="designTplConShow" width="30rem" :show-close="false" :before-close="designTplConClose">
      <el-form ref="form" :model="designTplForm" label-width="80px" style="padding-right: 2rem">
        <el-form-item label="模版名称">
          <el-input v-model="designTplForm.title"></el-input>
        </el-form-item>
        <el-form-item label="缩略图">
          <el-image :src="designTplForm.pic" style="width: 5rem" fit="contain"></el-image>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="designTplConShow = false">取 消</el-button>
        <el-button type="primary" @click="designTolFormSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import registerCom from "./coms/index";
// import comsArr from "./coms.js";
import lCon from "./design-l-con";
import conCom from "./design-r-con-com";
import conPage from "./design-r-con-page";
import conComEdit from "./design-r-coms";
export default {
  name: "active-page",
  components:{
    lCon,
    conCom,
    conComEdit,
    conPage
  },
  props: {
    active_mode:{
      type:Number,
      default:0,
    }
  },
  data() {
    return {
      floatArr:[
          "y-order-bullet-chat",
          "y-bottom-btn",
          "y-float-btn",
          "y-group-buy-bottom-btn",
      ],
      floatStyle:{
        "y-order-bullet-chat":"margin-top:2rem;",
        "y-float-btn":"margin-top:4rem;",
        "y-bottom-btn":"",
        "y-group-buy-bottom-btn":"",
      },
      designTplConShow:false,
      designTplForm:{
        title:"",
        pic:"",
        mode:0,
        content:"",
        bg_color: "",
        bg_audio: ""
      },
      publicPath: process.env.BASE_URL,
      drop_pos:"",
      con_arr:[],
      out_height:667,
      editor:{},
      title:"页面标题",
      coms:[],
      active_r_con:-1,
      active_r_con_coms:{},
      active_id:0,
      active_tpl_info:{
        bg_color:"#f8f8f8",
        bg_audio:""
      },
      loading:false,
    }
  },
  mounted() {
    this.active_id = parseInt(this.$route.query.id);
    // let conOne = comsArr[1];
    // conOne.id = new Date().getTime()+1;
    // this.coms.push(conOne)
    // let conTwo = comsArr[2];
    // conTwo.id = new Date().getTime()+2;
    // this.coms.push(conTwo);
    this.con_arr = registerCom(this.active_mode);
    this.editor = document.getElementById("editor");
    this.getCmd();
  },
  methods: {
    fetchDesignInfo(){
      this.loading = true;
      this.$api.active.designFetch({active_id:this.active_id}).then(res=>{
        if(res.content !== ""){
          this.coms = JSON.parse(res.content);
          this.setComs(this.coms);
          this.active_tpl_info.bg_color = res.bg_color;
          this.setBgColor(res.bg_color);
          this.active_tpl_info.bg_audio = res.bg_audio;
          this.setBgAudio(res.bg_audio);
        }
        this.loading = false;
      })
    },
    infoBgColorChange(e){
      this.active_tpl_info.bg_color = e;
      this.setBgColor(e);
    },
    infoBgAudioChange(e){
      this.active_tpl_info.bg_audio = e;
      this.setBgAudio(e);
    },
    designTolFormSubmit(){
      this.designTplForm.bg_color = this.active_tpl_info.bg_color;
      this.designTplForm.bg_audio = this.active_tpl_info.bg_audio;
      this.$api.active.designTplAdd(this.designTplForm).then(()=>{
        this.$message.success("保存成功");
        this.designTplConShow = false;
      })
    },
    designTplConClose(){
      this.designTplConShow = false;
      this.designTplForm = {
        title:"",
        pic:"",
        active_id:0,
        content:"",
        bg_color: "",
        bg_audio: ""
      }
    },
    makePic(){
      this.sendCmd("make_pic",{})
    },
    outPic(e){
      this.designTplForm.active_id = this.active_id;
      this.designTplConShow = true;
      this.designTplForm.content = JSON.stringify(this.coms)
      this.designTplForm.pic = e;
    },
    save(){
      if (this.coms.length === 0){
        this.$message.error("请先完成页面配置")
        return;
      }
      this.$api.active.designUpdate({
        active_id:this.active_id,
        bg_color: this.active_tpl_info.bg_color,
        bg_audio: this.active_tpl_info.bg_audio,
        content: JSON.stringify(this.coms)
      }).then(()=>{
        this.$message.success("保存成功");
      })

    },
    comsConfDel(){
      this.coms.splice(this.active_r_con,1);
      this.active_r_con = -1;
      this.setComs(this.coms);
    },
    comsConfChange(e){
      this.coms[this.active_r_con] = e;
      this.setComs(this.coms);
    },
    active_r_con_choose(key){
      this.active_r_con = key;
      this.active_r_con_coms = this.coms[key];
    },
    sortComs(coms){
      this.coms = coms;
      this.setComs(this.coms);
    },
    conAdd(index,key){
      this.coms.push(this.con_arr[index].list[key]);
      this.setComs(this.coms);
    },
    condrop(e,index){
      e.dataTransfer.setData("con_index",index)
    },
    drop(e){
      let con_index = e.dataTransfer.getData("con_index");
      let con_key = e.dataTransfer.getData("con_key");
      let con = this.con_arr[con_index].list[con_key];
      con.id = new Date().getTime()
      e.preventDefault();
      let drop_index = parseInt(e.target.dataset.index);
      let drop_name = e.target.dataset.name;
      if(drop_name == "y-placeholderview"){
        this.coms.splice(drop_index,1,con);
      }else {
        if(!drop_index && drop_index !== 0){
          if(e.offsetY < 44){
            this.coms.unshift(con);
          }else {
            this.coms.push(con);
          }
        }else {
          let insert_index = 0;
          if(e.offsetY > this.coms[drop_index].height/2){
            insert_index = drop_index + 1;
          }else {
            insert_index = drop_index;
          }
          this.coms.splice(insert_index,0,con);
        }
        this.placeholder_clear();
      }
      this.setComs(this.coms);
    },
    comsmove(e){
      e.preventDefault();
    },
    comenter(){
      if(this.drop_pos != "")return;
      let has_holder = false;
      this.coms.forEach((item)=>{
        if(item.name == "y-placeholderview"){
          has_holder = true;
        }
      })
      if(has_holder)return;
      this.coms.push(this.con_arr[0].list[0]);
      this.setComs(this.coms);
    },
    addcomenter(e){
      let drop_name = e.target.dataset.name;
      if (drop_name == "y-placeholderview")return;
      this.drop_pos = "";
    },
    addcomleave(e){
      e.preventDefault();
      let drop_name = e.target.dataset.name;
      if (drop_name == "y-placeholderview")return;
      this.drop_pos = "";
    },
    addcommove(e){
      e.preventDefault();
      let drop_name = e.target.dataset.name;
      if (drop_name == "y-placeholderview")return;
      let drop_index = parseInt(e.target.dataset.index);
      if(e.offsetY > this.coms[drop_index].height/2){
        if(this.drop_pos != "bottom"){
          this.drop_pos = "bottom";
          this.placeholder(drop_index+1);
        }
      }else {
        if(this.drop_pos != "top"){
          this.drop_pos = "top";
          this.placeholder(drop_index);
        }
      }
    },
    placeholder_clear(){
      this.coms.forEach((item,key)=>{
        if(item.name == "y-placeholderview"){
          this.coms.splice(key,1)
        }
      });
    },
    placeholder(index){
      this.placeholder_clear();
      this.coms.splice(index,0,this.con_arr[0].list[0]);
      this.setComs(this.coms);
    },
    initData(){
      if (!this.title)this.title = "新建页面";
      this.setTitle(this.title);
      if(!this.coms)this.coms = [];
      this.setComs(this.coms);
      this.fetchDesignInfo();
    },
    setComs(coms=[]){
      coms.forEach(item=>{
        if (item.name === "y-map"){
          item.data.map_key = this.$store.state.user.info.uni_account.tx_map_key
        }
      })
      this.sendCmd("set_coms",coms);
    },
    setBgColor(color){
      this.sendCmd("set_bg_color", {bg_color:color});
    },
    setBgAudio(audio){
      this.sendCmd("set_bg_audio", {bg_audio:audio});
    },
    setTitle(title=""){
      if(!title){
        this.$message.error('页面标题不能为空');
        return;
      }
      this.sendCmd("set_title",{title:title});
    },
    getCmd(){
      window.addEventListener("message",this.doseCmd)
    },
    doseCmd(e){
      switch (e.data.cmd){
        case "loaded":
          this.initData();
          break;
        case "out_height":
          if(e.data.params.height >667)this.out_height = e.data.params.height;
          this.coms = e.data.params.coms_list;
          break;
        case "out_pic":
          this.outPic(e.data.params.base64);
          break;
      }
    },
    sendCmd(func,params={}){
      this.editor.contentWindow.postMessage({
        cmd: func,
        params: params
      },'*');
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.doseCmd);
  }
}
</script>
<style>
  .header-title{
    user-select:none;
    font-weight: 600;
    font-size: 1rem;
    border-bottom: 1px solid #f8f8f8;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .y-aside::-webkit-scrollbar{
    width: 0;
  }

</style>
<style scoped>
  .con-body:hover>.con-left{
    border-right-color: #145bd4;
  }
  .r-con-field-btn:hover,
  .con-title:hover{
    background-color: #145bd4;
    color: #ffffff;
  }
  .btn-body-active .con-left{
    border-right-color: #145bd4;
  }
  .btn-body-active .con-title,
  .btn-active{
    background-color: #145bd4 !important;
    color: #ffffff;
  }
  .r-con-field-btn{
    line-height: 30px;
    padding: 0 10px;
    background-color: #ffffff;
    border-radius: 3px;
    cursor: pointer;
    user-select:none;
  }

  .con-panel{
    position: absolute;top: 0;left: 0;right: 0;bottom: 0;
  }
  .con-box{
    position: relative;
    width: 100%;
  }
  .con-body{
    position: absolute;
    margin-left: 100%;
    top:-10px;
    cursor:pointer;
  }
  .con-title{
    line-height: 20px;
    background-color: #ffffff;
    word-break: keep-all;
    padding: 2px 10px;
    border-radius: 5px;
    user-select:none;
    font-size: 12px;

  }
  .con-left{
    width: 0;
    height: 0;
    border-top: 5px solid rgba(0,0,0,0);
    border-right: 5px solid #ffffff;
    border-bottom: 5px solid rgba(0,0,0,0);
  }
</style>
