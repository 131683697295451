<template>
  <YTabs active-line>
    <YTabPane card name="manage" label="口令管理" >
      <div class="flex-def flex-zBetween" style="margin-bottom: 1rem">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="search" size="medium" placeholder="输入口令搜索"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button-group>
              <el-button @click="searchCode" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
              <el-button @click="searchReset" size="medium" native-type="reset">重置</el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
        <div>
          <el-button @click="exportExcel" type="primary" size="medium">导出</el-button>
        </div>
      </div>
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="code" label="口令"></el-table-column>
        <el-table-column label="用户昵称">
          <template #default="s">
            <div v-if="s.row.member">{{s.row.member.nickname}}</div>
          </template>
        </el-table-column>
        <el-table-column label="颁发时间">
          <template #default="s">{{s.row.grant_time | date}}</template>
        </el-table-column>
        <el-table-column label="生成时间">
          <template #default="s">{{s.row.created_at | date}}</template>
        </el-table-column>
        <el-table-column width="80" label="操作">
          <template #default="s">
            <el-button @click="delCode(s.$index)" size="mini">删除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="size" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </YTabPane>
    <YTabPane card name="make" label="生成口令">
      <el-form label-width="100px" inline>
        <el-form-item label="前缀">
          <el-input v-model="makeForm.pre"></el-input>
        </el-form-item>
        <el-form-item label="生成数量">
          <el-input v-model="makeForm.num"></el-input>
        </el-form-item>
        <el-form-item label="口令长度">
          <el-input v-model="makeForm.long"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="makeFormSubmit" type="primary" >生成</el-button>
        </el-form-item>
      </el-form>
    </YTabPane>
    <YTabPane card name="import" label="导入口令">
      <el-form label-width="200px">
        <el-form-item label="填入入群码每行一条">
          <el-input :rows="4" type="textarea" style="width: 40rem" v-model="importForm.codes"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="importFormSubmit" type="primary" >导入</el-button>
        </el-form-item>
      </el-form>
    </YTabPane>
    <YTabPane card name="grant" label="口令发放">
      <el-form label-width="100px">
        <el-form-item label="入群口令功能">
          <el-radio v-model="form.group_sign" :label="1" border>关闭</el-radio>
          <el-radio v-model="form.group_sign" :label="2" border>开启</el-radio>
        </el-form-item>
        <el-form-item label="支付控制">
          <el-radio v-model="form.group_sign_must_pay" :label="1" border>关闭</el-radio>
          <el-radio v-model="form.group_sign_must_pay" :label="2" border>开启</el-radio>
        </el-form-item>
        <el-form-item label="入群二维码">
          <yUploadImg v-model="form.group_qrcode" style="width: 20rem"></yUploadImg>
        </el-form-item>
        <el-form-item label="入群弹窗标题">
          <el-input v-model="form.group_box_title" style="width: 20rem"></el-input>
        </el-form-item>
        <el-form-item label="入群弹窗描述">
          <el-input v-model="form.group_box_desc" style="width: 20rem"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="saveActive" type="primary" >保存</el-button>
        </el-form-item>
      </el-form>
    </YTabPane>
  </YTabs>
<!--  <el-tabs type="border-card">-->
<!--    <el-tab-pane label="口令管理" >-->
<!--      <div class="flex-def flex-zBetween" style="margin-bottom: 1rem">-->
<!--        <el-form :inline="true" class="demo-form-inline">-->
<!--          <el-form-item>-->
<!--            <el-input v-model="search" size="medium" placeholder="输入口令搜索"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item>-->
<!--            <el-button-group>-->
<!--              <el-button @click="searchCode" size="medium" type="primary" icon="el-icon-search">搜索</el-button>-->
<!--              <el-button @click="searchReset" size="medium" native-type="reset">重置</el-button>-->
<!--            </el-button-group>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--        <div>-->
<!--          <el-button @click="exportExcel" type="primary" size="medium">导出</el-button>-->
<!--        </div>-->
<!--      </div>-->
<!--      <el-table v-loading="loading" :data="list" border style="width: 100%">-->
<!--        <el-table-column prop="id" label="编号" width="80"></el-table-column>-->
<!--        <el-table-column prop="code" label="口令"></el-table-column>-->
<!--        <el-table-column label="用户昵称">-->
<!--          <template #default="s">-->
<!--            <div v-if="s.row.member">{{s.row.member.nickname}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column label="颁发时间">-->
<!--          <template #default="s">{{s.row.grant_time | date}}</template>-->
<!--        </el-table-column>-->
<!--        <el-table-column label="生成时间">-->
<!--          <template #default="s">{{s.row.created_at | date}}</template>-->
<!--        </el-table-column>-->
<!--        <el-table-column width="80" label="操作">-->
<!--          <template #default="s">-->
<!--            <el-button @click="delCode(s.$index)" size="mini">删除</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <template #append>-->
<!--          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"-->
<!--                         :total="total" :page-size="size" :current-page="page" @current-change="pageChange">-->
<!--          </el-pagination>-->
<!--        </template>-->
<!--      </el-table>-->
<!--    </el-tab-pane>-->
<!--    <el-tab-pane label="生成口令">-->
<!--      <el-form label-width="100px" inline>-->
<!--        <el-form-item label="前缀">-->
<!--          <el-input v-model="makeForm.pre"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="生成数量">-->
<!--          <el-input v-model="makeForm.num"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="口令长度">-->
<!--          <el-input v-model="makeForm.long"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item>-->
<!--          <el-button @click="makeFormSubmit" type="primary" >生成</el-button>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-tab-pane>-->
<!--    <el-tab-pane label="导入口令">-->
<!--      <el-form label-width="200px">-->
<!--        <el-form-item label="填入入群码每行一条">-->
<!--          <el-input :rows="4" type="textarea" style="width: 40rem" v-model="importForm.codes"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item>-->
<!--          <el-button @click="importFormSubmit" type="primary" >导入</el-button>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-tab-pane>-->
<!--    <el-tab-pane label="口令发放">-->
<!--      <el-form label-width="100px">-->
<!--        <el-form-item label="入群口令功能">-->
<!--          <el-radio v-model="form.group_sign" :label="1" border>关闭</el-radio>-->
<!--          <el-radio v-model="form.group_sign" :label="2" border>开启</el-radio>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="支付控制">-->
<!--          <el-radio v-model="form.group_sign_must_pay" :label="1" border>关闭</el-radio>-->
<!--          <el-radio v-model="form.group_sign_must_pay" :label="2" border>开启</el-radio>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="入群二维码">-->
<!--          <yUploadImg v-model="form.group_qrcode" style="width: 20rem"></yUploadImg>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="入群弹窗标题">-->
<!--          <el-input v-model="form.group_box_title" style="width: 20rem"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="入群弹窗描述">-->
<!--          <el-input v-model="form.group_box_desc" style="width: 20rem"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item>-->
<!--          <el-button @click="saveActive" type="primary" >保存</el-button>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-tab-pane>-->
<!--  </el-tabs>-->
</template>

<script>
import yUploadImg from "@/components/y_upload_img";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";
export default {
  name: "edit_code",
  components:{YTabPane, YTabs, yUploadImg},
  data(){
    return{
      page:1,
      size:15,
      total:0,
      list:[],
      search:"",
      makeForm:{
        pre:"",
        num:0,
        long:24,
      },

      importForm:{
        codes:"",
      },
      form:{
        group_sign:1,
        group_sign_must_pay:1,
        group_qrcode:"",
        group_box_title:"",
        group_box_desc:""
      },
      loading:false,
    }
  },
  mounted() {
    this.loadCodePage();
    this.loadActive();
  },
  methods:{
    saveActive(){
      this.$api.active.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    loadActive(){
      this.$api.active.one({
        id:parseInt(this.$route.query.id)
      }).then(res=>{
        this.form = res;
      })
    },
    importFormSubmit(){
      this.$api.active.signCodeImport({
        active_id:parseInt(this.$route.query.id),
        codes:this.importForm.codes
      }).then(()=>{
        this.importForm.codes = "";
        this.$message.success("操作成功");
        this.loadCodePage();
      })
    },
    exportExcel(){
      this.$api.active.signCodeExport({
        active_id:parseInt(this.$route.query.id)
      }).then(res=>{
        location.href = res;
      })
    },
    delCode(index){
      let item = this.list[index];
      this.$api.active.signCodeDel({
        active_id:parseInt(this.$route.query.id),
        id:item.id
      }).then(()=>{
        this.$message.success("操作成功");
        this.loadCodePage();
      })
    },
    searchReset(){
      this.search = "";
      this.page = 1;
      this.loadCodePage();
    },
    searchCode(){
      this.page = 1;
      this.loadCodePage();
    },
    makeFormSubmit(){
      this.makeForm.active_id = parseInt(this.$route.query.id);
      this.makeForm.num = parseInt(this.makeForm.num);
      this.makeForm.long = parseInt(this.makeForm.long);
      if (this.makeForm.num <= 0){
        this.$message.error("生成数量不能为0");
        return;
      }
      this.$api.active.signCodeMake(this.makeForm).then(()=>{
        this.$message.success("操作成功");
        this.loadCodePage();
      })
    },
    pageChange(e){
      this.page = e;
      this.loadCodePage();
    },
    loadCodePage(){
      this.loading = true;
      this.$api.active.signCodeSearch({
        page:this.page,
        active_id:parseInt(this.$route.query.id),
        code:this.search
      }).then(res=>{
        this.loading = false;
        this.list = res.list;
        this.total =res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>