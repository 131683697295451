<template>
  <div class="flex-def flex-zCenter" v-loading="loading">
    <div style="width: 20rem">
      <y_choose_member @change="chooseU"></y_choose_member>
      <div style="margin: 25px 0 15px">
        <el-radio-group v-model="is_wxapp">
          <el-radio :label="false">公众号网页</el-radio>
          <el-radio :label="true">微信小程序</el-radio>
        </el-radio-group>
      </div>
      <el-button style="width: 100%;margin-top: .5rem" @click="generate">生成</el-button>
      <div v-if="out.qrcode" style="margin-top: 1rem" class="flex-def flex-zTopBottom flex-zCenter flex-cCenter">
        <div style="color: #909399;text-align: center">#{{ out.uid }}的物料二维码</div>
        <a :href="showQrcode" target="_blank">
          <img :src="showQrcode" style="width: 10rem;height: 10rem;border: 1px solid #ededed">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import y_choose_member from "@/components/y_choose_member";
import router from "@/router";
import store from "@/store";

export default {
  name: "large_qrcode",
  components: {
    y_choose_member
  },
  props: {
    active_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      uid: 0,
      is_wxapp: false,
      out: {
        uid: 0,
        qrcode: "",
      },
      loading: false,
    }
  },
  methods: {
    chooseU(e) {
      this.uid = e
    },
    generate() {
      this.loading = true;
      this.$api.active.qrcodeGenerate({
        uid: this.uid,
        is_wxapp: this.is_wxapp,
        active_id: this.active_id,
      }).then(res => {

        this.out = res;
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  computed:{
    showQrcode(){
      let value = this.out.qrcode;
      if(!value)return '';
      if (value.includes("http"))return value;
      let cur_uni_acid = router?.currentRoute?.params?.uni_acid;
      if (!cur_uni_acid){
        return store.state.setting.attachment_url + value;
      }
      return store.state.setting.uni_attachment_url[cur_uni_acid] + value;
    }
  }
}
</script>

<style scoped>

</style>