<template>
  <div>
    <el-form v-loading="loading" ref="form" :model="form" label-width="120px" style="width: 48rem">
      <el-form-item label="购买按钮">
        <div class="flex-def">
          <div class="flex-def" style="color: #909399;margin-right: 1rem">
            背景色
            <y_color_choose v-model="form.buy_btn_bg_color"></y_color_choose>
          </div>
          <div class="flex-def" style="color: #909399;margin-right: 1rem">
            文本色
            <y_color_choose v-model="form.buy_btn_font_color"></y_color_choose>
          </div>
          <div class="flex-def" style="color: #909399;margin-right: 1rem">
            文本
            <el-input v-model="form.buy_btn_text" size="small" style="width: 10rem"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="客服手机号">
        <el-input v-model="form.waiter_phone" style="width: 10rem"></el-input>
      </el-form-item>
      <el-form-item label="客服二维码">
        <yUploadImg v-model="form.waiter_qrcode" style="width: 40rem"></yUploadImg>
      </el-form-item>
      <el-form-item label="卡券列表标题">
        <el-input v-model="form.coupon_list_title"></el-input>
      </el-form-item>
      <el-form-item label="商家列表标题">
        <el-input v-model="form.merchant_list_title"></el-input>
      </el-form-item>
      <el-form-item label="活动详情">
        <y_editor v-model="form.detail" style="width: 40rem"></y_editor>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import yUploadImg from "@/components/y_upload_img";
import y_editor from "@/components/editor/y_editor";
import y_color_choose from "@/components/y_color_choose";
export default {
  name: "video_page",
  components:{
    yUploadImg,
    y_editor,
    y_color_choose,
  },
  data(){
    return{
      form:{
        buy_btn_bg_color:"",
        buy_btn_text:"",
        buy_btn_font_color:"",
        waiter_phone:"",
        waiter_qrcode:"",
        detail:"",
        coupon_list_title:"",
        merchant_list_title:"",
      },
      edit_id:0,
      loading:false,
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load()
    }
  },
  methods:{
    load(){
      this.loading = true;
      this.$api.active.one({id:this.edit_id}).then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
    onSubmit(){
      this.$api.active.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>