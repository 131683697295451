<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="启用">
        <el-radio v-model="form.share_gift" :label="2">开启</el-radio>
        <el-radio v-model="form.share_gift" :label="1">关闭</el-radio>
        <el-button @click="onSubmit" size="mini" type="primary">保存</el-button>
      </el-form-item>
    </el-form>
    <el-table border :data="share_gift_list" v-loading="loading">
      <el-table-column prop="coupon.title" label="奖品卡券"></el-table-column>
      <el-table-column prop="need_spread_num" label="领取条件"></el-table-column>
      <el-table-column>
        <template slot="header">
          <span>操作</span>
          <el-button @click="editPrizeBox=true" style="float: right" type="primary" size="mini">添加奖品</el-button>
        </template>
        <template slot-scope="s">
          <el-button @click="editShareGift(s.row)" size="mini" type="primary">编辑</el-button>
          <el-button @click="delShareGift(s.row.id)" size="mini" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加奖品" @close="closeShareGiftForm" destroy-on-close :visible.sync="editPrizeBox">
      <el-form :model="share_gift_form">
        <el-form-item label="需推荐人数">
          <el-input v-model="share_gift_form.need_spread_num">
            <template #prepend>推荐</template>
            <template #append>人成功购买</template>
          </el-input>
        </el-form-item>
        <el-form-item label="奖品设置">
          <y_choose_coupon v-model="share_gift_form.coupon_id"></y_choose_coupon>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeShareGiftForm">取 消</el-button>
        <el-button type="primary" @click="submitShareGift">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import y_choose_coupon from "@/components/y_choose_coupon";
export default {
  name: "share_gift",
  components:{
    y_choose_coupon
  },
  data(){
    return{
      form:{
        share_gift:1,
        share_gift_need_spread_num:0,
        share_gift_spread_coupon_id:0,
      },
      edit_id:0,
      loading:false,
      share_gift_list:[],
      share_gift_form:{
        need_spread_num:0,
        coupon_id:0,
      },
      editPrizeBox:false,
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
      this.loadShareGiftList();
    }
  },
  methods:{
    editShareGift(item){
      this.share_gift_form = {...item};
      this.share_gift_form.coupon = null
      this.editPrizeBox = true;
    },
    closeShareGiftForm(){
      this.share_gift_form = {
        need_spread_num:0,
        coupon_id:0,
      }
      this.editPrizeBox = false;
    },
    delShareGift(id){
      this.$api.active.shareGiftDel({
        active_id:this.edit_id,
        id
      }).then(()=>{
        this.$message.success("操作成功");
        this.loadShareGiftList();
      })
    },
    submitShareGift(){
      console.log(this.share_gift_form);
      this.share_gift_form.coupon_id = parseInt(this.share_gift_form.coupon_id);
      this.share_gift_form.need_spread_num = parseInt(this.share_gift_form.need_spread_num);
      this.$api.active.shareGiftEdit({
        active_id:this.edit_id,
        ...this.share_gift_form
      }).then(()=>{
        this.$message.success("操作成功");
        this.closeShareGiftForm();
        this.loadShareGiftList();
      })
    },
    loadShareGiftList(){
      this.loading = true;
      this.$api.active.shareGiftAll({
        active_id:this.edit_id
      }).then(res=>{
        this.share_gift_list = res;
        this.loading = false;
      })
    },
    load(){
      this.$api.active.one({id:this.edit_id}).then(res=>{
        this.form = res;
      })
    },
    onSubmit(){
      this.form.share_gift_need_spread_num = parseInt(this.form.share_gift_need_spread_num);
      this.$api.active.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>