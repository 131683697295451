<template>
  <div>
    <div class="flex-def flex-zBetween" style="margin-bottom: 1rem">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="用户昵称">
          <el-input v-model="searchForm.nickname" size="medium" placeholder="输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="访问时间">
          <el-date-picker
              v-model="searchForm.visit_between"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button @click="load" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
            <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <div>
        <el-button @click="exp" type="primary" size="medium">导出</el-button>
      </div>

    </div>
    <el-table :data="list" border :row-class-name="tableRowClassName" style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column label="微信头像" align="center" width="80">
        <template #default="scope">
          <el-avatar shape="square" size="small" :src="(scope.row.member ? scope.row.member.avatar : '') |  tomedia"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="member.nickname" label="微信昵称" ></el-table-column>
      <el-table-column label="分享人" >
        <template #default="scope">
          <div class="flex-def flex-cCenter" v-if="scope.row.from_member">
            <el-avatar shape="square" size="small" :src="scope.row.from_member.avatar | tomedia"></el-avatar>
            {{scope.row.from_member.nickname}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="服务员工" >
        <template #default="scope">
          <div class="flex-def flex-cCenter" v-if="scope.row.service_clerk_member">
            <el-avatar shape="square" size="small" :src="scope.row.service_clerk_member.avatar | tomedia"></el-avatar>
            {{scope.row.service_clerk_member.nickname}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="active.title" label="所属活动" ></el-table-column>
      <el-table-column prop="client_ip" label="访问ip"></el-table-column>
      <el-table-column prop="create_time" label="访问时间">
        <template #default="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "data_order",
  data(){
    return{
      page:1,
      size:15,
      total:0,
      list:[],
      searchForm:{
        nickname:"",
        visit_between:[],
      },
    }
  },
  mounted() {
    this.load()
  },
  methods:{
    tableRowClassName({rowIndex}) {
      if (rowIndex%2){
        return "warning-row"
      }else {
        return 'success-row';
      }
    },
    exp(){
      this.$api.active.recordVisitExport({
        active_id: parseInt(this.$route.query.id),
      }).then(res=>{
        location.href = res;
      })
    },
    resetSearch(){
      this.searchForm = {
        nickname:"",
        visit_between:[],
      }
      this.load();
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$api.active.recordVisit({
        active_id:parseInt(this.$route.query.id),
        nickname : this.searchForm.nickname,
        visit_between:this.searchForm.visit_between,
        page : this.page
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  }
}
</script>

<style scoped>

</style>