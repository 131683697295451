<template>
  <div>
    <el-table v-loading="loading" border :data="list">
      <el-table-column prop="merchant_id" label="商家编号"></el-table-column>
      <el-table-column prop="merchant_title" label="商家名称"></el-table-column>
      <el-table-column prop="balance" label="商家活动余额"></el-table-column>
      <el-table-column label="操作">
        <template #default="s">
          <el-button size="mini" @click="rechargeOpen(s.row)">充值</el-button>
          <el-button size="mini" @click="log(s.row)">余额变动记录</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="余额充值" width="20rem" :visible.sync="rechargeBox">
      <el-form :model="rechargeForm">
        <el-form-item label="充值金额">
          <el-input-number v-model="rechargeForm.fee"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="rechargeBox = false">取 消</el-button>
        <el-button type="primary" @click="rechargeSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="余额变动记录" :visible.sync="logBox" @close="logClose">
      <el-table v-loading="logLoading" :data="logList">
        <el-table-column prop="type" :formatter="logTypeFormatter" label="类型"></el-table-column>
        <el-table-column prop="log" label="记录" show-overflow-tooltip></el-table-column>
        <el-table-column label="时间" show-overflow-tooltip>
          <template #default="s">{{s.row.created_at | date}}</template>
        </el-table-column>
        <el-table-column prop="coupon_id" label="系统卡券编号"></el-table-column>
        <el-table-column prop="member_coupon_id" label="用户卡券编号"></el-table-column>
        <el-table-column prop="clerk_uid" label="操作员编号"></el-table-column>
        <el-table-column prop="uid" label="用户编号"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="logTotal" :page-size="15" :current-page="logPage" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "mode5_merchant",
  data() {
    return {
      edit_id: 0,
      loading: false,
      list: [],
      log_active_item: {},
      logLoading: false,
      logPage: 1,
      logTotal: 0,
      logList: [],
      logBox: false,
      rechargeBox: false,
      rechargeForm: {
        active_id: 0,
        merchant_id: 0,
        fee: 0
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.edit_id = parseInt(this.$route.query.id);
      this.load()
    }
  },
  methods: {
    pageChange(e) {
      this.logPage = e;
      this.log(this.log_active_item);
    },
    logTypeFormatter(e) {
      let str = "";
      switch (parseInt(e.type)) {
        case 1:
          str = "后台操作";
          break;
        case 2:
          str = "核销扣减";
          break;
        case 3:
          str = "分享奖励扣减";
          break;
      }
      return str
    },
    logClose(){
      this.log_active_item = {};
      this.logPage = 1;
      this.logList = []
      this.logTotal = 0;
    },
    log(item) {
      this.log_active_item = item;
      this.logBox = true;
      this.logLoading = true;
      this.$api.plugin.free.merchantLog({
        page: this.logPage,
        merchant_id: item.merchant_id,
        active_id: item.active_id
      }).then(res => {
        this.logLoading = false;
        this.logList = res.list
        this.logTotal = res.total;
      })
    },
    rechargeSubmit() {
      this.$api.plugin.free.merchantRecharge(this.rechargeForm).then(() => {
        this.$message.success("操作成功");
        this.rechargeBox = false;
        this.load();
      })
    },
    rechargeOpen(item) {
      this.rechargeForm = {
        active_id: item.active_id,
        merchant_id: item.merchant,
        fee: 0,
      }
      this.rechargeBox = true;
    },
    load() {
      this.loading = true;
      this.$api.plugin.free.merchantList({
        active_id: this.edit_id
      }).then(res => {
        this.list = res;
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>