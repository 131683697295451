<template>
  <div class="flex-def flex-zCenter">
    <div style="width: 20rem">
      <y_choose_member @change="chooseU"></y_choose_member>
      <el-button style="width: 100%;margin-top: .5rem" @click="qrcodeLink">生成</el-button>
      <div v-if="out.qrcode" style="margin-top: 1rem" class="flex-def flex-zTopBottom flex-zCenter flex-cCenter">
        <div style="color: #909399;text-align: center">#{{out.uid}}的物料二维码</div>
        <a :href="out.qrcode" target="_blank">
          <img :src="out.qrcode" style="width: 10rem;height: 10rem;border: 1px solid #ededed">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import y_choose_member from "@/components/y_choose_member";
export default {
  name: "safe_qrcode",
  components:{
    y_choose_member
  },
  props:{
    active_id:{
      type:Number,
      default:0
    }
  },
  data(){
    return{
      uid:0,
      out:{
        qrcode:"",
        uid:0,
      }
    }
  },
  methods:{
    qrcodeLink(){
      if(this.uid === 0)return this.$message.info("请先选择粉丝");
      this.out.qrcode = `${location.origin}/api/open/${this.$route.params.uni_acid}/qrcode/active-invite?active_id=${this.active_id}&from_uid=${this.uid}`
      this.out.uid = this.uid
    },
    chooseU(e){
      this.uid = e
    },
  }
}
</script>

<style scoped>

</style>