<template>
  <div>
    <div class="flex-def flex-zBetween" style="margin-bottom: 1rem">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="用户昵称">
          <el-input v-model="searchForm.nickname" size="medium" placeholder="输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="成团时间">
          <el-date-picker
              v-model="searchForm.between"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="成团状态">
          <el-select v-model="searchForm.status">
            <el-option label="全部" value=""></el-option>
            <el-option label="已成团" value="success"></el-option>
            <el-option label="未成团" value="wait"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button @click="load" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
            <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <div>
        <el-popover
            placement="left"
            width="50"
            trigger="click">
          <div>
            <el-checkbox-group v-model="listField">
              <el-checkbox label="head_id">团长参团编号</el-checkbox>
              <el-checkbox label="is_head">是否团长</el-checkbox>
              <el-checkbox label="join_id">参团编号</el-checkbox>
              <el-checkbox label="order_id">订单编号</el-checkbox>
              <el-checkbox label="member_id">用户编号</el-checkbox>
              <el-checkbox label="avatar">头像</el-checkbox>
              <el-checkbox label="nickname">昵称</el-checkbox>
              <el-checkbox label="status">状态</el-checkbox>
              <el-checkbox label="type">类型</el-checkbox>
              <el-checkbox label="success_time">成团时间</el-checkbox>
              <el-checkbox label="hands_close">活动结束系统成团</el-checkbox>
            </el-checkbox-group>
          </div>
          <el-button slot="reference" icon="el-icon-menu" type="primary" size="medium"></el-button>
        </el-popover>
        <el-button @click="exp" type="primary" size="medium">导出</el-button>
      </div>

    </div>
    <el-table v-loading="loading" :row-class-name="tableRowClassName" :data="list" border style="width: 100%">
      <el-table-column prop="progress.from_group" label="所属团" show-overflow-tooltip></el-table-column>
      <el-table-column label="拼团进度" >
        <template #default="s">{{s.row.progress.has}}/{{s.row.progress.need}}</template>
      </el-table-column>
      <el-table-column v-if="listField.includes('head_id')" prop="head_group_buy_id" label="团长参团编号"
                       ></el-table-column>
      <el-table-column v-if="listField.includes('is_head')" prop="is_head" :formatter="(item)=>item.is_head ? '团长' : '团员'"
                       label="是否团长" ></el-table-column>
      <el-table-column v-if="listField.includes('join_id')" prop="id" label="参团编号"></el-table-column>
      <el-table-column v-if="listField.includes('order_id')" prop="order_id" label="订单编号" ></el-table-column>
      <el-table-column v-if="listField.includes('member_id')" prop="order.member.id" label="用户编号" ></el-table-column>
      <el-table-column v-if="listField.includes('avatar')" label="头像" width="50" align="center">
        <template #default="scope">
          <el-avatar shape="square" size="small" :src="elAvatar(scope.row) | tomedia"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column v-if="listField.includes('nickname')" prop="order.member.nickname" label="昵称"
                       width="120" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="listField.includes('status')" prop="status" label="状态" show-overflow-tooltip>
        <template #default="s">
          <el-tag :type="s.row.status ? 'success' : 'danger'" effect="dark" size="small">
            {{ elStatus(s.row.status) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="listField.includes('type')" :formatter="payType" label="类型" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="listField.includes('success_time')" label="成团时间" show-overflow-tooltip>
        <template #default="s">{{ s.row.success_time | date }}</template>
      </el-table-column>
      <el-table-column v-if="listField.includes('hands_close')" prop="hands_close" :formatter="(item)=>item.hands_close ? '是' : ''"
                       label="活动结束系统成团"></el-table-column>
      <el-table-column label="操作">
        <template #default="s">
          <el-button @click="headJudgeStatus(s.row)" type="primary" size="small" v-if="s.row.is_head && !s.row.status">成团检测</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "group_buy",
  data() {
    return {
      searchForm: {
        nickname: "",
        status: "",
        between: []
      },
      page: 1,
      total: 0,
      list: [],
      loading: false,
      listField: [
        "head_id",
        "is_head",
        "join_id",
        "order_id",
        "member_id",
        "avatar",
        "nickname",
        "status",
        "type",
        "success_time",
        "hands_close",
      ],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    tableRowClassName({rowIndex}) {
      if (rowIndex%2){
        return "warning-row"
      }else {
        return 'success-row';
      }
    },
    headJudgeStatus(item){
      this.$api.plugin.groupBuy.headJudgeStatus({
        head_group_by_id:item.id,
        active_id:item.active_id
      }).then(res=>{
        this.$message.success(res);
        this.load();
      })
    },
    payType(item) {
      let str = "";
      switch (parseInt(item.type)) {
        case 0:
          str = "直接购买";
          break;
        case 1:
          str = "第二阶梯团";
          break;
        case 2:
          str = "第三阶梯团";
          break;
      }
      return str;
    },
    elAvatar(item) {
      return item?.order?.member?.avatar ? item?.order?.member?.avatar : "";
    },
    exp() {
      this.$api.plugin.groupBuy.groupListExp({
        active_id: parseInt(this.$route.query.id),
        list_field: this.listField
      }).then(res => {
        location.href = res;
      })
    },
    elStatus(status) {
      if (status) return "已成团";
      return "未成团";
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    resetSearch() {
      this.searchForm = {
        nickname: "",
        status: "",
        between: []
      }
      this.load();
    },
    load() {
      this.loading = true;
      this.$api.plugin.groupBuy.groupList({
        active_id: parseInt(this.$route.query.id),
        nickname: this.searchForm.nickname,
        status: this.searchForm.status,
        between: this.searchForm.between,
        page: this.page
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style>
.el-table__row div.cell {
  white-space: nowrap;
}
</style>