<template>
  <div>
    <div class="flex-def flex-zBetween" style="margin-bottom: 1rem">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="用户昵称">
          <el-input v-model="searchForm.nickname" size="medium" placeholder="输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="奖励类型">
          <el-select v-model="searchForm.reward_type">
            <el-option label="全部" :value="0"></el-option>
            <template v-if="active.mode !== 5">
              <el-option v-if="modeHasInvite" label="1级邀请订单" :value="1"></el-option>
              <el-option v-if="modeHasInvite2" label="2级邀请订单" :value="5"></el-option>
              <el-option label="转盘抽奖" :value="2"></el-option>
              <el-option label="分享红包" :value="3"></el-option>
              <el-option label="推荐礼品" :value="4"></el-option>
              <el-option label="员工服务奖励" :value="6"></el-option>
              <el-option label="九宫格抽奖" :value="7"></el-option>
              <el-option label="盲盒抽奖" :value="8"></el-option>
              <template v-if="classicSpecial">
                <el-option label="商家直推订单奖励" :value="9"></el-option>
                <el-option label="店员直推订单商家奖励" :value="10"></el-option>
                <el-option label="客户直推订单关联商家奖励" :value="11"></el-option>
                <el-option label="店员购买订单商家奖励" :value="12"></el-option>
                <el-option label="店员直推订单奖励" :value="13"></el-option>
                <el-option label="客户直推订单奖励-有关联商家" :value="14"></el-option>
                <el-option label="客户直推订单奖励-无关联商家" :value="15"></el-option>
              </template>
              <template v-if="groupBuySpecial">
                <el-option label="团长红包" :value="16"></el-option>
              </template>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="获奖时段">
          <el-date-picker
              v-model="searchForm.get_between"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button @click="load" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
            <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <div>
        <el-button @click="exp" type="primary" size="medium">导出</el-button>
      </div>
    </div>
    <template v-if="active.mode !== 5">
      <el-divider></el-divider>
      <el-row :gutter="20">
        <el-col v-if="modeHasInvite" :span="4" align="center">
          <div class="y-desc">1级邀请订单</div>
          <div style="width: 100%">{{ desc.invite.paid }}/{{ desc.invite.total }}元</div>
        </el-col>
        <el-col v-if="modeHasInvite2" :span="4" align="center">
          <div class="y-desc">2级邀请订单</div>
          <div style="width: 100%">{{ desc.invite2.paid }}/{{ desc.invite2.total }}元</div>
        </el-col>
        <el-col :span="4" align="center">
          <div class="y-desc">员工服务奖励</div>
          <div style="width: 100%">{{ desc.service_clerk.paid }}/{{ desc.service_clerk.total }}元</div>
        </el-col>
        <el-col :span="4" align="center">
          <div class="y-desc">分享红包</div>
          <div style="width: 100%">{{ desc.share.paid }}/{{ desc.share.total }}元</div>
        </el-col>
        <el-col :span="4" align="center">
          <div class="y-desc">转盘抽奖红包</div>
          <div style="width: 100%">{{ desc.turntable.paid }}/{{ desc.turntable.total }}元</div>
        </el-col>
        <el-col :span="4" align="center">
          <div class="y-desc">转盘抽奖卡券</div>
          <div style="width: 100%">{{ desc.turntable.coupon }}</div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
    </template>
    <el-table :data="list" border :row-class-name="tableRowClassName" style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column prop="active.title" label="所属活动"></el-table-column>
      <el-table-column prop="content" label="奖励内容"></el-table-column>
      <el-table-column prop="member.nickname" label="获利用户"></el-table-column>
      <el-table-column prop="from" label="奖励来源"></el-table-column>
      <el-table-column prop="create_time" label="获奖时间">
        <template #default="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "data_order",
  props:{
    active:{
      type:Object,
      default(){
        return{
          mode:0,
        }
      }
    },
  },
  data(){
    return{
      page:1,
      size:15,
      total:0,
      list:[],
      searchForm:{
        nickname:"",
        reward_type:0,
        get_between:[],
      },
      desc:{
        invite:{
          paid:0,
          total:0
        },
        invite2:{
          paid:0,
          total:0
        },
        share:{
          paid:0,
          total:0
        },
        service_clerk:{
          paid:0,
          total:0
        },
        turntable:{
          paid:0,
          total:0,
          coupon:0,
        },
      }
    }
  },
  mounted() {
    this.load();
    this.loadDesc();
  },
  computed:{
    groupBuySpecial(){
      return this.active.mode == 4;
    },
    classicSpecial(){
      return this.active.mode == 3;
    },
    modeHasInvite(){
      return this.active.mode != 3;
    },
    modeHasInvite2(){
      return this.active.mode != 3;
    },
  },
  methods: {
    tableRowClassName({rowIndex}) {
      if (rowIndex%2){
        return "warning-row"
      }else {
        return 'success-row';
      }
    },
    exp(){
      this.$api.active.recordRewardExport({
        active_id: parseInt(this.$route.query.id),
      }).then(res=>{
        location.href = res;
      })
    },
    loadDesc(){
      this.$api.active.recordRewardDesc({
        active_id:parseInt(this.$route.query.id)
      }).then(res=>{
        this.desc = res;
      })
    },
    resetSearch() {
      this.searchForm = {
        nickname: "",
        reward_type: 0,
        get_between: [],
      }
      this.load();
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$api.active.recordReward({
        active_id: parseInt(this.$route.query.id),
        nickname: this.searchForm.nickname,
        reward_type: this.searchForm.reward_type,
        get_between: this.searchForm.get_between,
        page: this.page
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  }
}
</script>

<style scoped>

</style>