<template>
  <div>
    <el-form v-loading="loading" ref="form" :model="form" label-width="120px" style="width: 50rem">
      <el-form-item label="绑定关系">
        <el-radio v-model="form.invite_bind_func" :label="1">链接绑定</el-radio>
        <el-radio v-model="form.invite_bind_func" :label="2">付款后绑定</el-radio>
      </el-form-item>
      <el-form-item label="推荐购买返利">
        <el-radio v-model="form.invite_award" :label="1">关闭</el-radio>
        <el-radio v-model="form.invite_award" :label="2">开启</el-radio>
      </el-form-item>
      <el-form-item label="1级购买返利">
        <el-input v-model="form.invite_award_fee" style="width: 10rem">
          <template #prepend>最低</template>
          <template #append>元</template>
        </el-input>
        <el-input v-model="form.invite_award_fee_max" style="width: 10rem">
          <template #prepend>最高</template>
          <template #append>元</template>
        </el-input>
        <div class="y-desc" style="line-height: 1rem">仅填写最低金额时为固定金额，填写最低及最高金额发放时为范围内随机</div>
        <div class="y-desc" style="line-height: 1rem">最低为0时不发放</div>
        <div class="y-desc" style="line-height: 1rem">所有佣金设置不能超活动支付金额</div>
      </el-form-item>
      <el-form-item label="2级购买返利">
        <el-input v-model="form.invite2_award_fee" style="width: 10rem">
          <template #prepend>最低</template>
          <template #append>元</template>
        </el-input>
        <el-input v-model="form.invite2_award_fee_max" style="width: 10rem">
          <template #prepend>最高</template>
          <template #append>元</template>
        </el-input>
        <div class="y-desc" style="line-height: 1rem">仅填写最低金额时为固定金额，填写最低及最高金额发放时为范围内随机</div>
        <div class="y-desc" style="line-height: 1rem">最低为0时不发放</div>
        <div class="y-desc" style="line-height: 1rem">所有佣金设置不能超活动支付金额</div>
      </el-form-item>
      <el-form-item label="返利限制">
        <el-radio v-model="form.invite_award_grant_limit" :label="1">推荐人无需支付</el-radio>
        <el-radio v-model="form.invite_award_grant_limit" :label="2">推荐人须支付</el-radio>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "default_invite",
  data(){
    return{
      form:{
        invite_bind_func:1,
        invite_award:1,
        invite_award_fee:0,
        invite_award_cash_func:2,
        invite_award_grant_limit:1,
        invite_award_fee_max:0,
        invite2_award_fee:0,
        invite2_award_fee_max:0,
        service_clerk_award:0,
      },
      edit_id:0,
      loading:false,
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load()
    }
  },
  methods:{
    load(){
      this.loading = true;
      this.$api.active.one({id:this.edit_id}).then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
    onSubmit(){
      this.form.invite_award_fee = parseFloat(this.form.invite_award_fee);
      this.form.invite_award_fee_max = parseFloat(this.form.invite_award_fee_max);
      this.form.invite2_award_fee = parseFloat(this.form.invite2_award_fee);
      this.form.invite2_award_fee_max = parseFloat(this.form.invite2_award_fee_max);
      this.$api.active.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>