<template>
  <el-form v-loading="loading" style="padding: 2rem;width: 50rem" ref="form" :model="form" label-width="100px">
    <el-form-item label="购买人数">
      <el-input v-model="form.fake_buy_num"></el-input>
      <span class="y-desc">为0时不显示虚拟值；用户端显示购买任务=实际购买人数+虚拟购买人数</span>
    </el-form-item>
    <el-form-item label="查看次数">
      <el-input v-model="form.fake_visit"></el-input>
      <span class="y-desc">为0时不显示虚拟值；用户端显示查看次数=实际查看次数+虚拟查看次数</span>
    </el-form-item>
    <el-form-item label="购买记录">
      <el-input v-model="form.fake_buy_order"></el-input>
      <span class="y-desc">为0时不显示虚拟值；用户端显示虚拟记录+真实购买记录</span>
    </el-form-item>
    <el-form-item label="虚拟数据">
      <el-radio v-model="form.fake" :label="1">关闭</el-radio>
      <el-radio v-model="form.fake" :label="2">启用</el-radio>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="formSubmit">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "edit_fake",
  data(){
    return{
      form:{
        fake:1,
        fake_buy_num:0,
        fake_visit:0,
        fake_buy_order:0,
      },
      loading:false,
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load()
    }
  },
  methods:{
    formSubmit() {
      this.form.fake_buy_num = parseInt(this.form.fake_buy_num);
      this.form.fake_buy_order = parseInt(this.form.fake_buy_order);
      this.form.fake_visit = parseInt(this.form.fake_visit);
      this.$api.active.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load(){
      this.loading = true;
      this.$api.active.one({id:this.edit_id}).then(res=>{
        this.loading = false;
        this.form = res;
      })
    },
  }
}
</script>

<style scoped>

</style>