<template>
  <div>
    <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom" style="margin-top: 2rem">
      <mode1 v-if="active_mode === 1" :active_id="parseInt($route.query.id)" style="width: 40rem"></mode1>
      <mode2 v-if="active_mode === 2" :active_id="parseInt($route.query.id)" style="width: 40rem"></mode2>
      <mode3 v-if="active_mode === 3" :active_id="parseInt($route.query.id)" style="width: 40rem"></mode3>
      <mode4 v-if="active_mode === 4" :active_id="parseInt($route.query.id)" style="width: 40rem"></mode4>
      <mode5 v-if="active_mode === 5" :active_id="parseInt($route.query.id)" style="width: 40rem"></mode5>
      <mode6 v-if="active_mode === 6" :active_id="parseInt($route.query.id)" style="width: 40rem"></mode6>
    </div>
    <div>
      <el-divider></el-divider>
      <div class="flex-def flex-warp flex-cCenter">
        <el-tag
            v-for="tag in managerList"
            :key="tag.id"
            closable
            @close="managerDel(tag.id)"
            type="success">
          {{ managerShowText(tag) }}
        </el-tag>
        <y_choose_member style="margin-left: .5rem" @change="managerAdd" :box="true" label="选择活动管理员"></y_choose_member>
      </div>
    </div>
  </div>
</template>

<script>
import mode1 from "@/view/active/add/mode1";
import mode2 from "@/view/active/add/mode2";
import mode3 from "@/view/active/add/mode3";
import mode4 from "@/view/active/add/mode4";
import y_choose_member from "@/components/y_choose_member";
import mode5 from "@/view/active/add/mode5";
import mode6 from "@/view/active/add/mode6";
export default {
  name: "edit_basic",
  components:{
    mode1,mode2,mode3,mode4,y_choose_member,mode5,mode6
  },
  props:{
    active_mode:{
      type:Number,
      default:0,
    }
  },
  data(){
    return{
      active_id:0,
      managerList:[],
    }
  },
  mounted() {
    this.active_id = parseInt(this.$route.query.id);
    this.loadManager();
  },
  methods:{
    managerShowText(item){
      return `#${item?.member?.id}:${item?.member?.nickname}`;
    },
    managerDel(id){
      this.$api.active.managerDel({
        manager_id:id,
        active_id:this.active_id
      }).then(()=>{
        this.$message.success("操作成功");
        this.loadManager();
      })
    },
    managerAdd(uid){
      this.$api.active.managerAdd({
        active_id:this.active_id,
        uid,
      }).then(()=>{
        this.$message.success("操作成功");
        this.loadManager();
      })
    },
    loadManager(){
      this.$api.active.managerAll({
        active_id:this.active_id
      }).then(res=>{
        this.managerList = res;
      })
    }
  }
}
</script>

<style scoped>

</style>